export type ActionButtonProps = {
    name: string;
    actionColor: ActionColor;
    activatedOn: () => boolean;
    clickHandler: () => void;
    mini?: boolean;
}

// TODO: Stylize and gather common styles into shared folders
export enum ActionColor {
    SUBMIT = "#3490dc", // Tailwind blue-500
    CANCEL = "#e3342f", // Tailwind red-500
    LOGOUT = "#e3342f", // Tailwind red-500
}

// TODO: Use locale system to assign name with different languages
export const ActionButton = (props: ActionButtonProps) => {
    const isDisabled = !props.activatedOn();
    const buttonClass = isDisabled ? "disabledButtonClass" : "enabledButtonClass";
    const buttonStyle = isDisabled ? { opacity: 0.25 } : {};
    const handleClick = isDisabled ? () => { } : props.clickHandler;
    const mainStyle = "text-white font-bold py-2 px-4 rounded";

    return (props.mini ? <div onClick={handleClick} style={{
        ...buttonStyle,
        margin: '4px',
        color: props.actionColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }}>
        {props.name}
    </div>
        :
        <div style={{ margin: "10px" }}>
            <button style={{ ...buttonStyle, backgroundColor: props.actionColor }} className={`${mainStyle} ${buttonClass}`} onClick={handleClick} disabled={isDisabled}>{props.name}</button>
        </div>);
}
