import api from "../";
import { sanitizeOTPFields } from "../../utils/validation/otp";
import { confirmOTPRequest, confirmOTPResult, requestOTPRequest } from "../api-schemas/otp";

export const requestOTP = async (body: requestOTPRequest): Promise<void> => {
    return api.post(process.env.REACT_APP_SERVER_URL + "/otp/request_otp_code", {
        phone_number: sanitizeOTPFields(body.phone_number),
    });
}

export const confirmOTP = async (body: confirmOTPRequest): Promise<confirmOTPResult> => {
    const response = await api.post(process.env.REACT_APP_SERVER_URL + "/otp/confirm_otp_code", {
        phone_number: sanitizeOTPFields(body.phone_number),
        code: sanitizeOTPFields(body.code),
    });
    return response as unknown as confirmOTPResult;
}
