import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import { AllCategoriesInterface, CategoryInterface } from '../interfaces/AllCategoriesInterface';
import { getCategoryResult } from '../../api-client/api-schemas/category';

const initialState: AllCategoriesInterface = {
    categories: [],
    indexedCategories: [],
    isLoading: false,
    isSuccessful: false,
    error: {}
}

export const allCategoriesSlice = createSlice({
    name: 'allCategories',
    initialState,
    reducers: {
        getAllCategoriesRequestAction: (state: any) => {
            state.isLoading = true;
        },
        getAllCategoriesSuccessAction: (state: any, action: PayloadAction<CategoryInterface[]>) => {
            state.categories = action.payload.sort((a: CategoryInterface, b: CategoryInterface) => a.ordering - b.ordering);
            const maxId = Math.max(...action.payload.map((category: getCategoryResult) => category.id));
            state.indexedCategories = new Array(maxId + 1).fill(null);
            action.payload.forEach((category: getCategoryResult) => {
                state.indexedCategories[category.id] = category;
            });
            state.isLoading = false;
            state.isSuccessful = true;
            state.result = action.payload;
        },
        getAllCategoriesFailedAction: (state: any, action: PayloadAction<{}>) => {
            state.isSuccessful = false;
            state.result = action.payload;
        },
        setNewCategoriesOrder: (state: any, action: PayloadAction<CategoryInterface[]>) => {
            //set ordering property of each category based on the index
            state.categories = action.payload.map((category: CategoryInterface, index: number) => { return category = {...category, ordering:index} })
            //TODO: implement the logic to update the order of categories on the server. Is this supposed to be done here?
        },
    },
});

export const {
    getAllCategoriesRequestAction,
    getAllCategoriesSuccessAction,
    getAllCategoriesFailedAction,
    setNewCategoriesOrder
} = allCategoriesSlice.actions;
export default allCategoriesSlice.reducer;
