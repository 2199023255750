export enum primaryConfigurations {
    ITEMS = "ITEMS",
    CATEGORIES = "CATEGORIES",
    SUBCATEGORIES = "SUBCATEGORIES",
    REFUNDS = "REFUNDS",
}

export enum modalConfigurations {
    CREATE_ITEM = "CREATE_ITEM",
    UPDATE_ITEM = "UPDATE_ITEM",
    DELETE_ITEM = "DELETE_ITEM",
    GET_ITEM_DETAILS = "GET_ITEM_DETAILS",
    REORDER_CATEGORIES = "REORDER_CATEGORIES",
    REORDER_SUBCATEGORIES = "REORDER_SUBCATEGORIES",
}

export type modalConfigMap = {
    [key: string]: { 
        displayName: string, 
        modalElement: JSX.Element, 
        cancellable: boolean 
    };
};

export type primaryConfigMap = {
    [key: string]: { 
        displayName: string, 
        bodyElement: JSX.Element, 
        secondarySidebarElement: JSX.Element 
    };
};
