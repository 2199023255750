import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import { AllItemsInterface, ItemInterface } from "../interfaces/AllItemsInterface";

const initialState: AllItemsInterface = {
    items: [],
    indexedItems: [],
    globalSelection: [],
    pageSize: 20,
    paginatedItems: [],
    isLoading: false,
    isSuccessful: false,
    error: {}
}

export const allItemsSlice = createSlice({
    name: 'allItems',
    initialState,
    reducers: {
        getAllItemsRequestAction: (state: any) => {
            state.isLoading = true;
        },
        getAllItemsSuccessAction: (state: any, action: PayloadAction<ItemInterface[]>) => {
            state.items = action.payload;
            const paginatedItems = [];
            for (let i = 0; i < action.payload.length; i += state.pageSize) {
                paginatedItems.push(action.payload.slice(i, i + state.pageSize));
            }
            state.paginatedItems = paginatedItems;
            const maxId = Math.max(...action.payload.map((item: ItemInterface) => item.id));
            state.indexedItems = new Array(maxId + 1).fill(null);
            if (!state.globalSelection || maxId + 1 > state.globalSelection.length) {
                state.globalSelection = new Array(maxId + 1).fill(false);
            }
            action.payload.forEach((item: ItemInterface) => {
                state.indexedItems[item.id] = item;
            });
            state.isLoading = false;
            state.isSuccessful = true;
            // TODO: Implement functionality to re-paginate items if page size is changed.
        },
        getAllItemsFailedAction: (state: any, action: PayloadAction<{}>) => {
            state.isSuccessful = false;
            state.result = action.payload;
        },
        selectItemByIdAction: (state: any, action: PayloadAction<number>) => { 
            state.globalSelection[action.payload] = !state.globalSelection[action.payload];
            // console.log(state.globalSelection.filter((selected: boolean, index: number) => selected).map((selected: boolean, index: number) => index));
            // with other selectable items; selection mechanisms don't scale w.r.t. items yet.
        }
    },
});

export const {
    getAllItemsRequestAction,
    getAllItemsSuccessAction,
    getAllItemsFailedAction,
    selectItemByIdAction
} = allItemsSlice.actions;
export default allItemsSlice.reducer;
