import { useState } from "react";
import { useSelector } from "react-redux";
import { locale, staticTranslate } from "../../../utils/translation";

// TODO: Stylize and gather common styles into shared folders
// TODO: Use locale system to assign name with different languages
export const DropdownField = (props: {
    name: string,
    state: number | undefined,
    options: { id: number, name: locale }[],
    inputHandler: (state: number) => void,
}) => {
    const [index, setIndex] = useState<number>(0);
    const settingsState = useSelector((state: any) => state.settings);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedIndex = parseInt(event.target.value);
        const selectedOptionId = props.options[selectedIndex]?.id ?? props.options[0].id;
        setIndex(selectedIndex);
        props.inputHandler(selectedOptionId);
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: 5, justifyContent: 'space-between' }}>
            <label style={{ marginRight: '10px', flex: '1', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {props.name}
            </label>

            <select style={{ flex: '3', border: '1px solid lightgray', borderRadius: 5, width: "100%" }}
                value={index}
                onChange={(e) => handleChange(e)} >
                {props.options.map((option: { id: number, name: { en: string, zh: string } }, index) => {
                    return (
                        <option key={index} value={index}>
                            {staticTranslate(option.name, settingsState.settings.language)}
                        </option>)
                })}
            </select>
        </div>
    );
}

