export type svgDisplayProps = {
    svgKey: string;
}

// TODO: Add a handler to trigger a modal or navigation state change.
// TODO: Stylize and gather common styles into shared folders
export const SVGDisplay = (props: svgDisplayProps) => {
    const svgUrl = `${process.env.REACT_APP_IMGIX_URL}/${props.svgKey}.svg`;
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
                <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <iframe 
                src={svgUrl} 
                title="SVG Display" 
                style={{
                    height: '80px',
                    width: '80px', // Adjust width as needed
                    border: 'none', // Removes the border around the iframe
                    overflow: 'hidden',
                    margin: '2px'
                }}
                frameBorder="0"
                allowFullScreen>
            </iframe>
        </div>
    </div>;
}
