import { useDispatch, useSelector } from "react-redux";
import { updateNavigation } from "../../../redux-store/reducer/navigationSlice";
import { modalConfigurations } from "../../../component-layout/navigation/navigation";
import { ActionButton, ActionColor } from "../../utils/buttons/actionButton";

// TODO: Stylize and gather common styles into shared folder
export const ItemsSecondarySidebar = () => {
    const dispatch = useDispatch();
    const clipboardState = useSelector((state: any) => state.clipboard);

    const showCreateItemModal = () => {
        dispatch(updateNavigation({ selectedModal: modalConfigurations.CREATE_ITEM }));
    }
    const showUpdateItemModal = () => {
        dispatch(updateNavigation({ selectedModal: modalConfigurations.UPDATE_ITEM }));
    }
    const showGetSelectedItemDetailsModal = () => {
        dispatch(updateNavigation({ selectedModal: modalConfigurations.GET_ITEM_DETAILS }));
    }
    const showDeleteItemModal = () => {
        dispatch(updateNavigation({ selectedModal: modalConfigurations.DELETE_ITEM }));
    }

    return (<>
        <ActionButton
            name="Create Item"
            clickHandler={showCreateItemModal}
            actionColor={ActionColor.SUBMIT}
            activatedOn={() => true}
        />

        <ActionButton
            name="Get Selected Item Details"
            clickHandler={showGetSelectedItemDetailsModal}
            actionColor={ActionColor.SUBMIT}
            activatedOn={() => true}
        />

        <ActionButton
            name="Update Selected Item"
            clickHandler={showUpdateItemModal}
            actionColor={ActionColor.SUBMIT}
            activatedOn={() => clipboardState.selectedItems.length == 1}
        />

        <ActionButton
            name="Delete Selected Item(s)"
            clickHandler={showDeleteItemModal}
            actionColor={ActionColor.SUBMIT}
            activatedOn={() => clipboardState.selectedItems.length >= 1}
        />
    </>)
}
