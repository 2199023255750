import { useDispatch } from "react-redux";
import { updateSettings } from "../../redux-store/reducer/settingsSlice";
import { Language, languageNames } from "../../utils/translation";
import { DropdownField } from "../utils/form/dropdownField";
import { useSelector } from "react-redux";

// TODO: Stylize and gather common styles into shared folder
export const SettingsPanel = () => {
    const dispatch = useDispatch();
    const settingsState = useSelector((state: any) => state.settings);

    return (
        <div className="m-2 p-2 border-2 border-gray-200 rounded-lg bg-gray-100">
            <DropdownField
                name="Language"
                state={Object.values(Language).indexOf(settingsState.settings.language)}
                options={Object.entries(languageNames).map(([key, value], index) => ({ id: index, name: value }))}
                inputHandler={(index: number) => {
                    dispatch(updateSettings({ language: Object.values(Language)[index] }))
                }}
            />
        </div>
    );
};

