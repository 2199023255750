import { useSelector } from "react-redux";
import { Language, dynamicTranslate, locale } from "../../../utils/translation";
import { EditableStringDisplay } from "./editableStringDisplay"

export type localeDisplayProps = {
    value: locale,
    autoTranslate?: boolean,
    id?: number,
    allowEditMode?: boolean,
    onSubmitEdit?: (id: number, value: locale) => Promise<any>,
}

// TODO: Stylize and gather common styles into shared folders
export const EditableLocaleDisplay = (props: localeDisplayProps) => {
    const settingsState = useSelector((state: any) => state.settings);
    const language: Language = settingsState.settings.language ?? Language.EN;
    return <EditableStringDisplay value={props.value[language]} id={props.id} allowEditMode={props.allowEditMode}
        onSubmitEdit={async (id: number, value: string) => {
            if (props.onSubmitEdit) {
                await props.onSubmitEdit(id, false ?
                    (await dynamicTranslate(value, language)) :
                    { ...props.value, [language]: value }
                );
            }
        }} />
}
