import { createItem, updateItem, deleteItem, getItem, getAllItems } from "./apis/item";
import { createCategory, updateCategory, deleteCategory, getCategory, getAllCategories } from "./apis/category";
import { createSubcategory, updateSubcategory, deleteSubcategory, getSubcategory, getAllSubcategories } from "./apis/subcategory";
import { requestOTP, confirmOTP } from "./apis/otp";
import { getAssignableOrderDeliveries } from "./apis/orderDelivery";
import { getAssignableOrders } from "./apis/order";

export type authentication = {
    token: string | undefined,
}
export const missingTokenMessage = "Your session has expired. Please refresh the page and log in again.";

export default class JustGoService {
    static createItem = createItem;
    static updateItem = updateItem;
    static deleteItem = deleteItem;
    static getItem = getItem;
    static getAllItems = getAllItems;

    static createCategory = createCategory;
    static updateCategory = updateCategory;
    static deleteCategory = deleteCategory;
    static getCategory = getCategory;
    static getAllCategories = getAllCategories;

    static createSubcategory = createSubcategory;
    static updateSubcategory = updateSubcategory;
    static deleteSubcategory = deleteSubcategory;
    static getSubcategory = getSubcategory;
    static getAllSubcategories = getAllSubcategories;

    static getAssignableOrders = getAssignableOrders;
    static getAssignableOrderDeliveries = getAssignableOrderDeliveries;

    static requestOTP = requestOTP;
    static confirmOTP = confirmOTP;
}
