import { useDispatch, useSelector } from "react-redux";
import { updateNavigation } from "../redux-store/reducer/navigationSlice";
import { modalConfig } from "./navigation/modalConfig";
import { ActionButton, ActionColor } from "../components/utils/buttons/actionButton";

export const ModalContainer = () => {
    const navigationState = useSelector((state: any) => state.navigation);
    const dispatch = useDispatch();
    const modalComponent = modalConfig[navigationState.navigation.selectedModal];
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 1000,
            display: modalComponent ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '10px',
                margin: '5px',
            }}>
                <div>
                    {modalComponent && modalComponent.cancellable && <div style={{ position: 'absolute', top: 0, right: 0, padding: '10px' }}>
                        <ActionButton name="Close"
                            actionColor={ActionColor.CANCEL}
                            activatedOn={() => true}
                            clickHandler={() => {
                                dispatch(updateNavigation({ selectedModal: null }));
                            }}
                        />
                    </div>}
                    <div>
                        {modalComponent && modalComponent.modalElement as any}
                    </div>
                </div>
            </div>
        </div>
    );
};
