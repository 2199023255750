import { translate } from '@vitalets/google-translate-api';

export enum Language {
    EN = "en",
    ZH = "zh",
}
export type locale = {
    [Language.EN]: string;
    [Language.ZH]: string;
}
export const languageNames = {
    [Language.EN]: {
        en: "English",
        zh: "英语",
    },
    [Language.ZH]: {
        en: "Chinese",
        zh: "中文",
    }
}

export const staticTranslate = (message: locale, language: Language): string => {
    return language === Language.EN ? message.en : message.zh;
}

export const dynamicTranslate = async (message: string, language: Language): Promise<locale> => {
    // TODO: Either disdable CORS to access this API or bypass CORS by using a proxy or homegrown API.
    try {
        const translatedToZh = await translate(message, {to: 'zh-cn'});
        const translatedToEn = language === Language.ZH ? await translate(message, {to: 'en'}) : { text: message };
        return {
            en: typeof translatedToEn === 'string' ? translatedToEn : translatedToEn.text,
            zh: typeof translatedToZh === 'string' ? translatedToZh : translatedToZh.text
        }
    } catch (error) {
        console.error("Translation error:", error);
        return {
            en: language === Language.EN ? message : "",
            zh: language === Language.ZH ? message : ""
        };
    }
}
