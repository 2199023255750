import { useEffect } from "react";

export type selectorButtonProps = {
    parentId: number,
    globalSelection: boolean[],
    toggleSelect: () => void
}

// TODO: Implement selection mechanism
// TODO: Stylize and gather common styles into shared folders
export const SelectorButton = (props: selectorButtonProps) => {
    const selectItemHandler = () => {
        props.toggleSelect();
    }

    useEffect(() => {
    }, [props.parentId, props.globalSelection]);

    return (
        <div onMouseDown={() => {
            selectItemHandler();
        }} style={{
            width: '100px',
            height: '60px',
            borderRadius: '10px',
            backgroundColor: props.globalSelection[props.parentId] ? '#60a5fa' : '#e5e7eb',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease' // Added transition effect
        }}>
        </div>
    )
}
