import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNavigation } from '../redux-store/reducer/navigationSlice';
import { primaryConfig } from './navigation/primaryConfig';
import { primaryConfigurations } from './navigation/navigation';

export type sidebarProps = {
    minExpandedWidth: number;
    initExpandedWidth: number;
    collapsedWidth: number;
}

// TODO: Stylize and gather common styles into shared folder
export const PrimarySidebar = (props: sidebarProps) => {
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(true);
    const [dynamicWidth, setDynamicWidth] = useState(props.initExpandedWidth);
    const navigationState = useSelector((state: any) => state.navigation);

    const toggleSidebar = () => setIsExpanded(!isExpanded);

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault(); // Prevents unwanted text selection during drag.
        const startX = e.clientX;
        const startWidth = dynamicWidth;

        const handleMouseMove = (e: MouseEvent) => {
            e.preventDefault(); // Prevents unwanted text selection during drag.
            const currentWidth = Math.max(startWidth + e.clientX - startX, props.minExpandedWidth);
            setDynamicWidth(currentWidth);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    return (
        <div style={{
            width: isExpanded ? `${dynamicWidth}px` : `${props.collapsedWidth}px`,
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            margin: '10px',
            borderRadius: '5px',
            position: 'relative',
        }}>
            <button onClick={toggleSidebar} style={{
                float: 'right',
                margin: '10px',
                borderRadius: '5px',
                border: 'none',
                backgroundColor: '#f0f0f0',
                cursor: 'pointer',
                padding: '5px 10px'
            }}>
                {isExpanded ? '<' : '>'}
            </button>
            <div onMouseDown={handleMouseDown} style={{
                cursor: 'ew-resize',
                position: 'absolute',
                top: 0, right: 0, bottom: 0,
                width: '5px',
                backgroundColor: 'rgba(0,0,0,0.1)'
            }}></div>
            <ul style={{
                listStyleType: 'none',
                padding: '10px',
                margin: '0'
            }}>
                {isExpanded && Object.keys(primaryConfig).map((option, index) => (
                    <li key={index} style={{
                        padding: '10px',
                        backgroundColor: navigationState.navigation.selectedPrimary === option ? '#eeeeee' : 'transparent', // Change the background color based on the selected view
                        cursor: 'pointer',
                        borderRadius: '5px',
                        margin: '5px 0'
                    }}
                        onClick={() => dispatch(updateNavigation({ selectedPrimary: option as primaryConfigurations }))}
                    >
                        {primaryConfig[option].displayName}
                    </li>
                ))}
            </ul>
        </div>
    );
}
