import { useSelector } from "react-redux";
import { primaryConfig } from "./navigation/primaryConfig";

// TODO: Add left-right scrolling for overflow content.
export const BodyContainer = () => {
    const navigationState = useSelector((state: any) => state.navigation);
    const bodyComponent = primaryConfig[navigationState.navigation.selectedPrimary].bodyElement;

    return (
        <div className="flex flex-col" style={{ margin: 5, width: '100%' }}>
            {primaryConfig && bodyComponent}
        </div>
    );
}
