import { useSelector } from "react-redux";
import { staticTranslate, locale } from "../../../utils/translation";
import { useEffect, useState } from "react";
import { ErrorDisplay } from "../error/errorDisplay";
import { ActionButton, ActionColor } from "../buttons/actionButton";

export type localeDropdownDisplayProps = {
    parentId: number,
    initialIndex: number,
    indexedLocaleValues: ({ index: number, value: locale } | null)[],
    filter?: (content: { index: number, value: locale }) => boolean,
    onSubmitEdit: (parent_id: number, id: number) => Promise<any>,
}

// TODO: Refactor in terms of dropdown components and hierarchical components
// TODO: Stylize and gather common styles into shared folders
export const EditableIndexedContentDisplay = (props: localeDropdownDisplayProps) => {
    const [contentIndex, setContentIndex] = useState<number>(props.initialIndex);
    const settingsState = useSelector((state: any) => state.settings);

    const [editMode, setEditMode] = useState(false);
    const [editIndex, setEditIndex] = useState<number>(props.initialIndex);
    const [indexHistory, setIndexHistory] = useState<number[]>([props.initialIndex]);
    const [error, setError] = useState<Error | null>(null);

    const initEditModeHandler = () => {
        setEditMode(true);
    }
    const submitHandler = async () => {
        if (props.onSubmitEdit) {
            try {
                await props.onSubmitEdit(props.parentId, editIndex);
                setIndexHistory([...indexHistory, editIndex]);
                setContentIndex(editIndex);
                setError(null);
                setEditMode(false);
            } catch (error) {
                setError(error as Error);
            }
        } else {
            setContentIndex(props.initialIndex);
        }
    }
    const undoHandler = async () => {
        if (props.onSubmitEdit && indexHistory.length > 1) {
            try {
                const previousValue = indexHistory[indexHistory.length - 2];
                await props.onSubmitEdit(props.parentId, previousValue);
                setContentIndex(previousValue);
                setIndexHistory(indexHistory.slice(0, indexHistory.length - 1));
                setError(null);
                setEditMode(false);
            } catch (error) {
                setError(error as Error);
            }
        }
    }
    const cancelHandler = () => {
        setEditMode(false);
        setEditIndex(contentIndex);
    }

    const valueStyle = {
        padding: 1,
        borderRadius: 5,
        border: editMode ? '1px solid gray' : '1px solid transparent',
        paddingRight: 5,
        textOverflow: 'ellipsis',
    }
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setEditIndex(parseInt(event.target.value));
    };
    useEffect(() => {
        setContentIndex(props.initialIndex);
    }, [props.initialIndex, props.parentId]);
    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: 5, justifyContent: 'space-between' }}>
            {error && <ErrorDisplay error={error} />}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', marginLeft: 10, }}>
                {editMode ? (
                    <>
                        <ActionButton mini={true} name="Submit" actionColor={ActionColor.SUBMIT} clickHandler={submitHandler} activatedOn={() => !!(contentIndex || editMode)} />
                        <ActionButton mini={true} name="Cancel" actionColor={ActionColor.CANCEL} clickHandler={cancelHandler} activatedOn={() => true} />
                    </>
                ) : (
                    <>
                        <ActionButton mini={true} name="Edit" actionColor={ActionColor.SUBMIT} clickHandler={initEditModeHandler} activatedOn={() => true} />
                        {indexHistory.length > 1 && <ActionButton mini={true} name="Undo" actionColor={ActionColor.SUBMIT} clickHandler={undoHandler} activatedOn={() => true} />}
                    </>
                )}
            </div>
            <div style={{ minWidth: '100px', margin: '2px 20px 2px 20px', marginRight: 20, display: 'flex', alignItems: 'center', width: '100%' }}>
                {editMode ? (
                    <select style={{ flex: '3', border: '1px solid lightgray', borderRadius: 5, width: "100%" }}
                        value={editIndex}
                        onChange={(e) => handleChange(e)} >
                        {props.indexedLocaleValues
                            .filter((content) => content != null)
                            .filter((content) => props.filter ? props.filter(content!) : true)
                            .sort(
                                (a, b) => staticTranslate(props.indexedLocaleValues[a!.index]!.value, settingsState.settings.language).localeCompare
                                    (staticTranslate(props.indexedLocaleValues[b!.index]!.value, settingsState.settings.language)))
                            .map((content: { index: number, value: locale } | null) => {
                                if (!content) return null;
                                return (
                                    <option key={content.index} value={content.index}>
                                        {staticTranslate(props.indexedLocaleValues[content.index]!.value, settingsState.settings.language)}
                                    </option>)
                            })}
                    </select>
                ) : (
                    <div style={{ ...valueStyle, opacity: props.indexedLocaleValues[contentIndex] ? '1' : '0.25', width: '100%', display: 'flex', alignItems: 'center' }}>
                        {staticTranslate(props.indexedLocaleValues[contentIndex]!.value, settingsState.settings.language)}
                    </div>
                )}
            </div>
        </div>
    );
}
