// TODO: Stylize and gather common styles into shared folders
// TODO: Use locale system to assign name with different languages
export const ImageField = (props: {
    name: string,
    state: string | undefined,
    inputHandler: (state: string) => void,
}) => {
    const uploadImage = async (file: File) => {
        const formData = new FormData();
        formData.append("image", file);

        try {
            // TODO: Implement the code that uploads the image to the proper S3 bucket.
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            uploadImage(event.target.files[0]);
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ marginRight: '10px', flex: '1' }}>{props.name}</label>
            <input style={{ flex: '3' }} type="file" onChange={handleChange} />
        </div>
    );
}
