import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import { ClipboardInterface } from '../interfaces/ClipboardInterface';
import { itemKey, itemValues } from '../../api-client/api-schemas/item';

const initialState: ClipboardInterface = {
    selectedItems: [],
    isLoading: false,
    isSuccessful: false,
    error: {}
}

export const clipboardSlice = createSlice({
    name: 'clipboard',
    initialState,
    reducers: {
        setSelectedItems: (state: any, action: PayloadAction<(Partial<itemKey> & Partial<itemValues>)[]>) => {
            // TODO: Handle the ClipboardInterface a bit better.
            state.selectedItems = action.payload;
            state.isLoading = true;
        },
        setClipboardSucceeded: (state: any, action: PayloadAction<{}>) => {
            state.isLoading = false;
            state.isSuccessful = true;
            state.result = action.payload;
        },
        setClipoardFailed: (state: any, action: PayloadAction<{}>) => {
            state.isSuccessful = false;
            state.result = action.payload;
        },
    },
});

export const {
    setSelectedItems,
    setClipboardSucceeded,
    setClipoardFailed,
} = clipboardSlice.actions;
export default clipboardSlice.reducer;
