import api from "../";
import { authentication, missingTokenMessage } from "../JustGoService";
import { getAssignableOrderDeliveriesRequest, getAssignableOrderDeliveriesResult } from "../api-schemas/orderDelivery";

export const getAssignableOrderDeliveries = async (body: getAssignableOrderDeliveriesRequest & authentication): Promise<Partial<getAssignableOrderDeliveriesResult>> => {
    if (body.token) {
        const response = await api.get<getAssignableOrderDeliveriesResult>(process.env.REACT_APP_SERVER_URL + "/dispatcher/assignable_order_deliveries", {
            headers: {
                Authorization: `Bearer ${body.token}`
            },
        });
        return response as unknown as Partial<getAssignableOrderDeliveriesResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}
