// TODO: Stylize and gather common styles into shared folders
// TODO: Use locale system to assign name with different languages
export const StringField = (props: {
    name: string,
    state: string | undefined,
    inputHandler: (state: string) => void,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.inputHandler(event.target.value);
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: 5, justifyContent: 'space-between' }}>
            <label style={{ marginRight: '10px', flex: '1', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.name}</label>
            <input type="text" value={props.state} onChange={handleChange} style={{ flex: '3', border: '1px solid lightgray', borderRadius: 5, width: "100%" }} />
        </div>
    );
}
