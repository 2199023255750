import api from "../";
import { authentication, missingTokenMessage } from "../JustGoService";
import { createCategoryRequest, createCategoryResult, deleteCategoryRequest, deleteCategoryResult, getAllCategoriesRequest, getAllCategoriesResult, getCategoryRequest, getCategoryResult, updateCategoryRequest, updateCategoryResult } from "../api-schemas/category";

// Partial promise types are not ideal, but they are a quick band-aid to ensure two-way compatibility between frontend and backend schemas. 
export const createCategory = async (body: createCategoryRequest & authentication): Promise<Partial<createCategoryResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/categories/create", body);
        return response as unknown as Partial<createCategoryResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const updateCategory = async (body: updateCategoryRequest & authentication): Promise<Partial<updateCategoryResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/categories/update", body);
        return response as unknown as Partial<updateCategoryResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const deleteCategory = async (body: deleteCategoryRequest & authentication): Promise<Partial<deleteCategoryResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/categories/delete", body);
        return response as unknown as Partial<deleteCategoryResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const getCategory = async (body: getCategoryRequest & authentication): Promise<Partial<getCategoryResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/categories/get", body);
        return response as unknown as Partial<getCategoryResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const getAllCategories = async (body: getAllCategoriesRequest & authentication): Promise<getAllCategoriesResult> => {
    if (body.token.length > 0) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/categories/get_all", body);
        return response as unknown as getAllCategoriesResult;
    } else {
        throw new Error(missingTokenMessage);
    }
}
