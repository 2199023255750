import React, { useState } from 'react';
import { primaryConfig } from './navigation/primaryConfig';
import { useSelector } from 'react-redux';

export type sidebarProps = {
    minExpandedWidth: number;
    initExpandedWidth: number;
    collapsedWidth: number;
}

// TODO: Stylize and gather common styles into shared folder
export const SecondarySidebar = (props: sidebarProps) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [dynamicWidth, setDynamicWidth] = useState(props.initExpandedWidth);
    const navigationState = useSelector((state: any) => state.navigation);

    const toggleSidebar = () => setIsExpanded(!isExpanded);
    const sidebarComponent = primaryConfig[navigationState.navigation.selectedPrimary].secondarySidebarElement;

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault(); // Prevents unwanted text selection during drag.
        const startX = e.clientX;
        const startWidth = dynamicWidth;

        const handleMouseMove = (e: MouseEvent) => {
            e.preventDefault(); // Prevents unwanted text selection during drag.
            const currentWidth = Math.max(startWidth - (e.clientX - startX), props.minExpandedWidth);
            setDynamicWidth(currentWidth);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    return (
        <div style={{
            width: isExpanded ? `${dynamicWidth}px` : `${props.collapsedWidth}px`,
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            margin: '10px',
            padding: '20px',
            borderRadius: '5px',
            position: 'relative',
        }}>
            <button onClick={toggleSidebar} style={{ float: 'left', margin: '10px', borderRadius: '5px', border: 'none', backgroundColor: '#f0f0f0', cursor: 'pointer', padding: '5px 10px' }}>
                {isExpanded ? '>' : '<'}
            </button>
            <div onMouseDown={handleMouseDown} style={{
                cursor: 'ew-resize',
                position: 'absolute',
                top: 0, left: 0, bottom: 0,
                width: '5px',
                backgroundColor: 'rgba(0,0,0,0.1)'
            }}>
            </div>
            {isExpanded && sidebarComponent}
        </div>
    );
}
