import api from "../";
import { authentication, missingTokenMessage } from "../JustGoService";
import { createSubcategoryRequest, createSubcategoryResult, deleteSubcategoryRequest, deleteSubcategoryResult, getAllSubcategoriesRequest, getAllSubcategoriesResult, getSubcategoryRequest, getSubcategoryResult, updateSubcategoryRequest, updateSubcategoryResult } from "../api-schemas/subcategory";

// Partial promise types are not ideal, but they are a quick band-aid to ensure two-way compatibility between frontend and backend schemas. 
export const createSubcategory = async (body: createSubcategoryRequest & authentication): Promise<Partial<createSubcategoryResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/subcategories/create", body);
        return response as unknown as Partial<createSubcategoryResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const updateSubcategory = async (body: updateSubcategoryRequest & authentication): Promise<Partial<updateSubcategoryResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/subcategories/update", body);
        return response as unknown as Partial<updateSubcategoryResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const deleteSubcategory = async (body: deleteSubcategoryRequest & authentication): Promise<Partial<deleteSubcategoryResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/subcategories/delete", body);
        return response as unknown as Partial<deleteSubcategoryResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const getSubcategory = async (body: getSubcategoryRequest & authentication): Promise<Partial<getSubcategoryResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/subcategories/get", body);
        return response as unknown as Partial<getSubcategoryResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const getAllSubcategories = async (body: getAllSubcategoriesRequest & authentication): Promise<getAllSubcategoriesResult> => {
    if (body.token.length > 0) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/subcategories/get_all", body);
        return response as unknown as getAllSubcategoriesResult;
    } else {
        throw new Error(missingTokenMessage);
    }
}
