import { useDispatch } from "react-redux";
import { updateNavigation } from "../../../redux-store/reducer/navigationSlice";
import { modalConfigurations } from "../../../component-layout/navigation/navigation";
import { ActionButton, ActionColor } from "../../utils/buttons/actionButton";

// TODO: Stylize and gather common styles into shared folder
export const CategoriesSecondarySidebar = () => {
    const dispatch = useDispatch();

    const showReorderCategoriesModal = () => {
        dispatch(updateNavigation({ selectedModal: modalConfigurations.REORDER_CATEGORIES }));
    }

    return (<>
        <ActionButton
            name={"Reorder Categories"}
            actionColor={ActionColor.SUBMIT}
            activatedOn={() => true}
            clickHandler={showReorderCategoriesModal}
        />
        <div> Disclaimer: The ordering in the table to the left does not reflect the actual ordering, which is correct in the "Reorder Categories" modal.  </div>
    </>)
}
