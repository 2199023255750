import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import { OrderInterface, OrdersInterface } from '../interfaces/OrdersInterface';

const initialState: OrdersInterface = {
    orders: [],
    indexedOrders: [],
    isLoading: false,
    isSuccessful: false,
    error: {}
}

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        getDeliverableOrdersRequestAction: (state: any) => {
            state.isLoading = true;
        },
        getDeliverableOrdersSuccessAction: (state: any, action: PayloadAction<OrderInterface[]>) => {
            state.orders = action.payload;
            const maxId = Math.max(...action.payload.map((order: OrderInterface) => order.id));
            state.indexedOrders = new Array(maxId + 1).fill(null);
            action.payload.forEach((order: OrderInterface) => {
                state.indexedOrders[order.id] = order;
            });
            state.isLoading = false;
            state.isSuccessful = true;
        },
        getDeliverableOrdersFailedAction: (state: any, action: PayloadAction<{}>) => {
            state.isSuccessful = false;
            state.result = action.payload;
        },
    },
});

export const {
    getDeliverableOrdersRequestAction,
    getDeliverableOrdersSuccessAction,
    getDeliverableOrdersFailedAction,
} = ordersSlice.actions;
export default ordersSlice.reducer;
