import { ItemsBody } from "../../components/primaries/bodies/ItemsBody";
import { primaryConfigMap } from "./navigation";
import { ItemsSecondarySidebar } from "../../components/primaries/secondarySidebars/ItemsSecondarySidebar";
import { CategoriesBody } from "../../components/primaries/bodies/CategoriesBody";
import { CategoriesSecondarySidebar } from "../../components/primaries/secondarySidebars/CategoriesSecondarySidebar";
import { SubcategoriesBody } from "../../components/primaries/bodies/SubcategoriesBody";
import { SubcategoriesSecondSidebar } from "../../components/primaries/secondarySidebars/SubcategoriesSecondSidebar";
import { MapBody } from "../../components/primaries/bodies/MapBody";

export const primaryConfig: primaryConfigMap = {
    ITEMS: { 
        displayName: "Items", 
        bodyElement: <ItemsBody/>, 
        secondarySidebarElement: <ItemsSecondarySidebar/> 
    },
    CATEGORIES: { 
        displayName: "Categories",
        bodyElement: <CategoriesBody/>, 
        secondarySidebarElement: <CategoriesSecondarySidebar/> 
    },
    SUBCATEGORIES: {
        displayName: "Subcategories",
        bodyElement: <SubcategoriesBody/>, 
        secondarySidebarElement: <SubcategoriesSecondSidebar/> 
    },
    MAP: {
        displayName: "Map",
        bodyElement: <MapBody/>, 
        secondarySidebarElement: <div>The current dispatcher map feature provides a view of pending orders that can be matched to the original admin panel, where the map is no longer fully functioning. You still need to use the original admin panel to dispatch orders. </div> 
    },
    REFUNDS: {
        displayName: "Refunds",
        bodyElement: <div>TODO</div>, 
        secondarySidebarElement: <div>Hello</div> 
    }
}
