export type errorDisplayProps = {
  error: Error;
}

// TODO: Stylize and gather common styles into shared folders
export const ErrorDisplay = (props: errorDisplayProps) => {
  return <div style={{
    border: '1px solid #dd0000',
    backgroundColor: '#ffdddd',
    borderRadius: '10px',
    color: '#dd0000',
    padding: '10px',
    marginBottom: '20px',
    width: '100%',
    boxSizing: 'border-box',
  }}>
    <h1>Error: {props.error.message}</h1>
  </div>;
};
