import { of, from} from "rxjs";
import { catchError, map, mergeMap } from 'rxjs/operators';

import {ofType} from "redux-observable";
import { updateNavigation, updateNavigationFailed, updateNavigationSucceeded } from "../reducer/navigationSlice";

export const navigationEpic = (action$: any, state$: any) => {
    return action$.pipe(
        ofType(updateNavigation.type),
        mergeMap((action: any) => {
            return from(
                new Promise((resolve) => {
                    setTimeout(() => {
                        return action;
                    }, 0);
                })
            ).pipe(
                map((action: any) => {
                    return of(updateNavigationSucceeded({}));
                }),
                catchError((err) => {
                    let result = {
                        message: err
                    }
                    return of(updateNavigationFailed(result));
                })
            )
        }),
    );
};
