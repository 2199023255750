import {combineEpics} from "redux-observable";
import {allItemsRequestEpic} from "./epic/allItemsEpic";
import { authTokenEpic } from './epic/authTokenEpic';
import { clipboardEpic } from "./epic/clipboardEpic";
import { settingsEpic } from "./epic/settingsEpic";
import { navigationEpic } from "./epic/navigationEpic";
import { allCategoriesEpic } from "./epic/allCategoriesEpic";
import { allSubcategoriesEpic } from "./epic/allSubcategoriesEpic";
import { ordersEpic } from "./epic/ordersEpic";
import { orderDeliveriesEpic } from "./epic/orderDeliveriesEpic";

export const rootEpic = combineEpics(
    allItemsRequestEpic,
    allCategoriesEpic,
    allSubcategoriesEpic,
    authTokenEpic,
    clipboardEpic,
    settingsEpic,
    navigationEpic,
    ordersEpic,
    orderDeliveriesEpic,
);
