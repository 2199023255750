import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllCategoriesRequestAction, setNewCategoriesOrder } from "../../../redux-store/reducer/allCategoriesSlice";
import { CategoryInterface } from "../../../redux-store/interfaces/AllCategoriesInterface";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import JustGoService from "../../../api-client/JustGoService";
import { ActionButton, ActionColor } from "../../utils/buttons/actionButton";
import { ErrorDisplay } from "../../utils/error/errorDisplay";
import { updateNavigation } from "../../../redux-store/reducer/navigationSlice";

export function ReorderCategories() {
    const allCategoriesState = useSelector((state: any) => state.allCategories);
    const authTokenState = useSelector((state: any) => state.authToken);
    const [updatedCategories, setUpdatedCategories] = useState<CategoryInterface[]>(allCategoriesState.categories);
    const [error, setError] = useState<Error | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCategoriesRequestAction({ token: authTokenState.authToken } as any));
    }, [dispatch]);

    const handleDragEnd = async (result: any) => {
        const { source, destination } = result;
        if (!destination) { return; }
        const updatedCategoriesCopy: CategoryInterface[] = Array.from(updatedCategories);
        const [movedCategory] = updatedCategoriesCopy.splice(source.index, 1);
        updatedCategoriesCopy.splice(destination.index, 0, movedCategory);
        setUpdatedCategories(updatedCategoriesCopy);
    };

    const handleSubmit = async () => {
        try {
            updatedCategories.forEach((category: CategoryInterface, index: number) => {
                JustGoService.updateCategory({
                    token: authTokenState.authToken,
                    id: category.id,
                    ordering: index,
                });
            });
            dispatch(setNewCategoriesOrder(updatedCategories));
            dispatch(updateNavigation({
                selectedModal: null
            }));
            setError(null);
        } catch (error) {
            setError(error as Error);
        }
    }

    const handleExit = () => {
        dispatch(updateNavigation({
            selectedModal: null
        }));
    }

    return (
        <div style={{ height: '800px', overflowY: 'scroll' }}>
        {error && <ErrorDisplay error={error} />}
        <DragDropContext onDragEnd={handleDragEnd}>
            <table className="table-auto">
                <thead>
                    <tr>
                        <th className="px-4 py-2">Name (EN)</th>
                        <th className="px-4 py-2">Name (ZH)</th>
                    </tr>
                </thead>
                <Droppable droppableId="categories" type="group" >
                    {(provided) => (
                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                            {updatedCategories.map((category: CategoryInterface, index: number) => (
                                <Draggable key={category.id} draggableId={category.name.en} index={index}>
                                    {(provided) => (
                                        <tr
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <td className="px-4 py-2">{category.name.en}</td>
                                            <td className="px-4 py-2">{category.name.zh}</td>
                                            {/*<td className="px-4 py-2">{category.ordering}</td>*/}
                                        </tr>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </tbody>
                    )}
                </Droppable>
            </table>
        </DragDropContext>
        <ActionButton name="Save" actionColor={ActionColor.SUBMIT} activatedOn={() => JSON.stringify(updatedCategories) !== JSON.stringify(allCategoriesState.categories)} clickHandler={handleSubmit} />
        <ActionButton name="Exit" actionColor={ActionColor.CANCEL} activatedOn={() => true} clickHandler={handleExit} />
        </div>
    );
}

export default ReorderCategories;