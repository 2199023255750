import { useState } from "react";
import { updateItemRequest } from "../../../api-client/api-schemas/item";
import { StringField } from "../../utils/form/stringField";
import { ErrorDisplay } from "../../utils/error/errorDisplay";
import JustGoService, { authentication } from "../../../api-client/JustGoService";
import { useDispatch, useSelector } from "react-redux";
import { SmallIntField } from "../../utils/form/smallIntField";
import { ImageField } from "../../utils/form/imageField";
import { DropdownField } from "../../utils/form/dropdownField";
import { PriceField } from "../../utils/form/priceField";
import { updateNavigation } from "../../../redux-store/reducer/navigationSlice";
import { dynamicTranslate } from "../../../utils/translation";
import { ActionButton, ActionColor } from "../../utils/buttons/actionButton";

// TODO: Stylize and gather common styles into shared folder
export const UpdatedSelectedItemModal = (props: any) => {

    const dispatch = useDispatch();

    const authTokenState = useSelector((state: any) => state.authToken);
    const categoriesState = useSelector((state: any) => state.allCategories);
    const subcategoriesState = useSelector((state: any) => state.allSubcategories);
    const settingsState = useSelector((state: any) => state.settings);
    const clipboardState = useSelector((state: any) => state.clipboard);
    const selectedItem = (clipboardState.selectedItems && clipboardState.selectedItems.length == 1) ? clipboardState.selectedItems[0] : null;

    const [itemName, setItemName] = useState<string>("");
    const [subcategoryId, setSubcategoryId] = useState<number>(selectedItem?.subcategory_id ?? 0);
    const selectedCategory = categoriesState.categories.find((category: any) => category.id === (subcategoriesState.subcategories.find((sub: any) => sub.id === subcategoryId)?.category_id));
    const [categoryId, setCategoryId] = useState<number>(selectedCategory?.id ?? 0);
    const [sku, setSku] = useState<string>(selectedItem?.sku ?? "");
    const [shelfNumber, setShelfNumber] = useState<string>(selectedItem?.shelf_number ?? "");
    const [lowStockThreshold, setLowStockThreshold] = useState<number>(selectedItem?.low_stock_threshold ?? 0);
    const [imageKey, setImageKey] = useState<string>(selectedItem?.image_key ?? "");
    const [theoreticalPrice, setTheoreticalPrice] = useState<number>(selectedItem?.theoretical_price ?? 0);
    const [price, setPrice] = useState<number>(selectedItem?.price ?? 0);

    const [error, setError] = useState<Error | null>(null);

    if (selectedItem == null) {
        dispatch(updateNavigation({ selectedModal: null }));
    }

    const submitHandler = async () => {
        try {
            const response = await JustGoService.updateItem({
                id: selectedItem.id,
                token: authTokenState.authToken,
                name: await dynamicTranslate(itemName, settingsState.settings.language),
                ordering: 0,
                subcategory_id: subcategoryId,
                sku: sku,
                shelf_number: shelfNumber,
                low_stock_threshold: lowStockThreshold,
                image_key: imageKey,
                theoretical_price: theoreticalPrice,
                price: price
            } as updateItemRequest & authentication);
            setError(null);
            dispatch(updateNavigation({ selectedModal: null }));
        } catch (error) {
            setError(error as Error);
        }
    }

    const cancelHandler = async () => {
        dispatch(updateNavigation({ selectedModal: null }));
    }

    // TODO: Refactor with a hierarchical selector for Category and Subcategory
    return (<div>
        <h2>Update Item</h2>
        {error && <ErrorDisplay error={error} />}
        <div style={{
            margin: '10px'
        }}>
            <StringField name="Item Name" state={itemName}
                inputHandler={(input: string) => setItemName(input)} />
            <DropdownField name="Category" state={categoryId}
                options={categoriesState ? categoriesState.categories.map((category: any) => {
                    return {
                        id: category.id,
                        name: category.name,
                    }
                }) : []}
                inputHandler={(input: number) => setCategoryId(input)} />
            <DropdownField name="Subcategory" state={subcategoryId}
                options={subcategoriesState ? subcategoriesState.subcategories
                    .filter((subcategory: any) => subcategory.category_id === categoryId)
                    .map((subcategory: any) => {
                        return {
                            id: subcategory.id,
                            name: subcategory.name,
                        }
                    }) : []}
                inputHandler={(input: number) => setSubcategoryId(input)} />
            <StringField name="SKU" state={sku}
                inputHandler={(input: string) => setSku(input)} />
            <StringField name="Shelf Number" state={shelfNumber}
                inputHandler={(input: string) => setShelfNumber(input)} />
            <SmallIntField name="Low Stock Threshold" state={lowStockThreshold}
                inputHandler={(input: number) => setLowStockThreshold(input)} />

            <ImageField name="Image" state={imageKey}
                inputHandler={(input: string) => setImageKey(input)} />
            <PriceField name="Theoretical Price" state={theoreticalPrice}
                inputHandler={(input: number) => setTheoreticalPrice(input)} />
            <PriceField name="Price" state={price}
                inputHandler={(input: number) => setPrice(input)} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <ActionButton name={"Submit"}
                actionColor={ActionColor.SUBMIT}
                activatedOn={() => true} // TODO: Validate
                clickHandler={submitHandler} />
            <ActionButton name={"Cancel"}
                actionColor={ActionColor.CANCEL}
                activatedOn={() => true}
                clickHandler={cancelHandler} />
        </div>
    </div>);
}
