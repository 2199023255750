export type imageDisplayProps = {
    imageKey: string;
}

// TODO: Add a handler to trigger a modal or navigation state change.
// TODO: Stylize and gather common styles into shared folders
export const ImageDisplay = (props: imageDisplayProps) => {
    const imageUrl = `${process.env.REACT_APP_IMGIX_URL}/${props.imageKey}`;
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <img src={imageUrl} alt={imageUrl} style={{
            height: '80px',
            margin: '2px'
        }} />
    </div>;
}
