import api from "../";
import {authentication, missingTokenMessage } from "../JustGoService";
import { getOrdersRequest, getOrdersResult } from "../api-schemas/order";

export const getAssignableOrders = async (body: getOrdersRequest & authentication): Promise<Partial<getOrdersResult>> => {
    if (body.token) {
        const response = await api.get<any>(process.env.REACT_APP_SERVER_URL + '/orders?paid=true&delivered=false', {
            headers: {
                Authorization: `Bearer ${body.token}`
            },
        });
        return response as unknown as Partial<getOrdersResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}
