import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import { NavigationInterface, navigation } from '../interfaces/NavigationInterface';
import { primaryConfigurations } from '../../component-layout/navigation/navigation';

const initialState: NavigationInterface = {
    navigation: {
        selectedPrimary: primaryConfigurations.ITEMS,
        selectedModal: null,
    },
    isLoading: false,
    isSuccessful: false,
    error: {}
}

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        updateNavigation: (state: any, action: PayloadAction<Partial<navigation>>) => {
            state.navigation = {...state.navigation, ...action.payload};
            state.isLoading = true;
        },
        updateNavigationSucceeded: (state: any, action: PayloadAction<{}>) => {
            state.isLoading = false;
            state.isSuccessful = true;
            state.result = action.payload;
        },
        updateNavigationFailed: (state: any, action: PayloadAction<{}>) => {
            state.isSuccessful = false;
            state.result = action.payload;
        },
    },
});

export const {
    updateNavigation,
    updateNavigationSucceeded,
    updateNavigationFailed,
} = navigationSlice.actions;
export default navigationSlice.reducer;
