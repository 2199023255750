import { combineReducers } from '@reduxjs/toolkit';
import allItemsSlice from './reducer/allItemsSlice';
import authTokenSlice from './reducer/authTokenSlice';
import clipboardSlice from './reducer/clipboardSlice';
import settingsSlice from './reducer/settingsSlice';
import navigationSlice from './reducer/navigationSlice';
import allCategoriesSlice from './reducer/allCategoriesSlice';
import allSubcategoriesSlice from './reducer/allSubcategoriesSlice';
import ordersSlice from './reducer/ordersSlice';
import orderDeliveriesSlice from './reducer/orderDeliveriesSlice';

const rootReducer = combineReducers({
    allItems: allItemsSlice,
    allCategories: allCategoriesSlice,
    allSubcategories: allSubcategoriesSlice,
    authToken: authTokenSlice,
    clipboard: clipboardSlice,
    settings: settingsSlice,
    navigation: navigationSlice,
    orders: ordersSlice,
    orderDeliveries: orderDeliveriesSlice,
});

export default rootReducer;
