import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import { SettingsInterface, settings } from '../interfaces/SettingsInterface';
import { Language } from '../../utils/translation';

const initialState: SettingsInterface = {
    settings: {
        language: Language.EN,
    },
    isLoading: false,
    isSuccessful: false,
    error: {}
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateSettings: (state: any, action: PayloadAction<Partial<settings>>) => {
            state.settings = {...state.settings, ...action.payload};
            state.isLoading = true;
        },
        updateSettingsSucceeded: (state: any, action: PayloadAction<{}>) => {
            state.isLoading = false;
            state.isSuccessful = true;
            state.result = action.payload;
        },
        updateSettingsFailed: (state: any, action: PayloadAction<{}>) => {
            state.isSuccessful = false;
            state.result = action.payload;
        },
    },
});

export const {
    updateSettings,
    updateSettingsSucceeded,
    updateSettingsFailed,
} = settingsSlice.actions;
export default settingsSlice.reducer;
