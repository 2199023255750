import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import { AllSubcategoriesInterface, SubcategoryInterface } from '../interfaces/AllSubcategoriesInterface';

const initialState: AllSubcategoriesInterface = {
    subcategories: [],
    indexedSubcategories: [],
    isLoading: false,
    isSuccessful: false,
    error: {}
}

export const allSubcategoriesSlice = createSlice({
    name: 'allSubcategories',
    initialState,
    reducers: {
        getAllSubcategoriesRequestAction: (state: any) => {
            state.isLoading = true;
        },
        getAllSubcategoriesSuccessAction: (state: any, action: PayloadAction<SubcategoryInterface[]>) => {
            state.subcategories = action.payload
                .sort((a: SubcategoryInterface, b: SubcategoryInterface) => a.ordering - b.ordering);
            const maxId = Math.max(...action.payload.map((subcategory: SubcategoryInterface) => subcategory.id));
            state.indexedSubcategories = new Array(maxId + 1).fill(null);
            action.payload.forEach((subcategory: SubcategoryInterface) => {
                state.indexedSubcategories[subcategory.id] = subcategory;
            });
            state.isLoading = false;
            state.isSuccessful = true;
        },
        getAllSubcategoriesFailedAction: (state: any, action: PayloadAction<{}>) => {
            state.isSuccessful = false;
            state.result = action.payload;
        },
        setNewSubcategoriesOrder: (state: any, action: PayloadAction<SubcategoryInterface[]>) => {
            //set ordering property of each category based on the index
            state.subcategories = action.payload.map((subcategory: SubcategoryInterface, index: number) => { return subcategory = {...subcategory, ordering:index} })
            //TODO: implement the logic to update the order of categories on the server. Is this supposed to be done here?
        },
    },
});

export const {
    getAllSubcategoriesRequestAction,
    getAllSubcategoriesSuccessAction,
    getAllSubcategoriesFailedAction,
    setNewSubcategoriesOrder,
} = allSubcategoriesSlice.actions;
export default allSubcategoriesSlice.reducer;
