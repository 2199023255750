import { of, from} from "rxjs";
import { catchError, map, mergeMap } from 'rxjs/operators';
import {ofType} from "redux-observable";
import {
    setSelectedItems,
    setClipoardFailed,
    setClipboardSucceeded,
} from "../reducer/clipboardSlice";

export const clipboardEpic = (action$: any, state$: any) => {
    return action$.pipe(
        ofType(setSelectedItems.type),
        mergeMap((action: any) => {
            return from(
                new Promise((resolve) => {
                    setTimeout(() => {
                        return action;
                    }, 0);
                })
            ).pipe(
                map((action: any) => {
                    return of(setClipboardSucceeded(action.payload));
                }),
                catchError((err) => {
                    let result = {
                        message: err
                    }
                    return of(setClipoardFailed(result));
                })
            )
        }),
    );
};
