import api from "../";
import { authentication, missingTokenMessage } from "../JustGoService";
import { createItemRequest, createItemResult, deleteItemRequest, deleteItemResult, getAllItemsRequest, getAllItemsResult, getItemRequest, getItemResult, updateItemRequest, updateItemResult } from "../api-schemas/item";

// Partial promise types are not ideal, but they are a quick band-aid to ensure two-way compatibility between frontend and backend schemas. 
export const createItem = async (body: createItemRequest & authentication): Promise<Partial<createItemResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/items/create", body);
        return response as unknown as Partial<createItemResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const updateItem = async (body: updateItemRequest & authentication): Promise<Partial<updateItemResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/items/update", body);
        return response as unknown as Partial<updateItemResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const deleteItem = async (body: deleteItemRequest & authentication): Promise<Partial<deleteItemResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/items/delete", body);
        return response as unknown as Partial<deleteItemResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const getItem = async (body: getItemRequest & authentication): Promise<Partial<getItemResult>> => {
    if (body.token) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/items/get", body);
        return response as unknown as Partial<getItemResult>;
    } else {
        throw new Error(missingTokenMessage);
    }
}

export const getAllItems = async (body: getAllItemsRequest & authentication): Promise<getAllItemsResult> => {
    if (body.token.length > 0) {
        const response = await api.post(process.env.REACT_APP_SERVER_URL + "/admin/items/get_all", body);
        return response as unknown as getAllItemsResult;
    } else {
        throw new Error(missingTokenMessage);
    }
}
