import {catchError, from, map, mergeMap, of} from "rxjs";
import {ofType} from "redux-observable";
import {
    getAllItemsRequestAction,
    getAllItemsSuccessAction,
    getAllItemsFailedAction,
} from "../reducer/allItemsSlice";
import JustGoService from "../../api-client/JustGoService";

export const allItemsRequestEpic = (action$: any, state$: any) => {
    return action$.pipe(
        ofType(getAllItemsRequestAction.type),
        mergeMap((action: any) =>
            from(
                JustGoService.getAllItems(action.payload)
            ).pipe(
                map((response: any) => {
                    if (response.data) {
                        return getAllItemsSuccessAction(response.data);
                    } else {
                        throw response;
                    }
                }),
                catchError((err) => {
                    let result = {
                        message: err
                    }
                    return of(getAllItemsFailedAction(result));
                })
            )
        )
    );
};
