import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import JustGoService from "../../api-client/JustGoService";
import { setAuthTokenRequestAction, setAuthTokenSuccessAction } from "../../redux-store/reducer/authTokenSlice";
import { ActionButton, ActionColor } from "../utils/buttons/actionButton";
import { getAllItemsSuccessAction } from "../../redux-store/reducer/allItemsSlice";
import { setSelectedItems } from "../../redux-store/reducer/clipboardSlice";
import { StringField } from "../utils/form/stringField";
import { ErrorDisplay } from "../utils/error/errorDisplay";

// TODO: Stylize and gather common styles into shared folder
export function AuthPanel() {
    const authTokenState = useSelector((state: any) => state.authToken);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const dispatch = useDispatch();

    const [error, setError] = useState<Error | null>(null);

    const requestOTPHandler = async () => {
        try {
            await JustGoService.requestOTP({ phone_number: phoneNumber });
            setOtpSent(true);
            setError(null);
        } catch (error) {
            setError(error as Error);
            console.error("Error sending phone number; try refreshing the page. ", error);
        }
    };

    const confirmOTPHandler = async () => {
        try {
            dispatch(setAuthTokenRequestAction({ phone_number: phoneNumber, code: otp } as any));
            setError(null);
        } catch (error) {
            setError(error as Error);
            console.error("Error verifying OTP; try refreshing the page. ", error);
        }
    };

    return (
        <>
            {error && <ErrorDisplay error={error} />}
            {authTokenState?.authToken ? (
                <ActionButton
                    name={"Logout"}
                    actionColor={ActionColor.LOGOUT}
                    activatedOn={() => true}
                    clickHandler={() => {
                        dispatch(setAuthTokenSuccessAction(""));
                        dispatch(getAllItemsSuccessAction([]));
                        dispatch(setSelectedItems([]));
                    }}
                />
            ) : (
                <div className="flex flex-col items-center justify-center p-4">
                    <StringField
                        name="Phone Number"
                        state={phoneNumber}
                        inputHandler={(input) => setPhoneNumber(input)}
                    />
                    {!otpSent ? (
                        <ActionButton
                            name={"Send OTP"}
                            actionColor={ActionColor.SUBMIT}
                            activatedOn={() => true} // TODO: Add a check to validate phone number.
                            clickHandler={async () => {
                                await requestOTPHandler();
                            }}
                        />
                    ) : (
                        <>
                            <StringField
                                name="OTP"
                                state={otp}
                                inputHandler={(input) => setOtp(input)}
                            />
                            <ActionButton
                                name={"Confirm OTP"}
                                actionColor={ActionColor.SUBMIT}
                                activatedOn={() => true} // TODO: Add a check to validate phone number.
                                clickHandler={async () => {
                                    await confirmOTPHandler();
                                }}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default AuthPanel;
