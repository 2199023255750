import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import JustGoService from '../../api-client/JustGoService';
import { 
  setAuthTokenFailedAction,
  setAuthTokenRequestAction, 
  setAuthTokenSuccessAction
} from '../reducer/authTokenSlice';
import { ofType } from 'redux-observable';

export const authTokenEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(setAuthTokenRequestAction.type),
    mergeMap((action: any) => 
      from(
        JustGoService.confirmOTP(action.payload)
      ).pipe(
        map((response: any) => {
          if (response.data.token) {
            return setAuthTokenSuccessAction(response.data.token);
          } else {
            throw response;
          }
        }),
        catchError((err) => {
          let result = {
              message: err
          }
          return of(setAuthTokenFailedAction(result));
        })
      )
    )
  );
}
