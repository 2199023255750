// TODO: Stylize and gather common styles into shared folders
// TODO: Add validation constraints
// TODO: Use locale system to assign name with different languages
export const PriceField = (props: {
    name: string,
    state: number | undefined,
    inputHandler: (state: number) => void,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(event.target.value).toFixed(2);
        const numericValue = parseFloat(newValue);
        if (!isNaN(numericValue)) {
            props.inputHandler(numericValue);
        }
    };
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 5, flexWrap: 'wrap' }}>
            <label style={{ marginRight: '10px', flex: '1', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.name}</label>
            <input type="number" value={props.state?.toFixed(2) ?? ''} onChange={handleChange} step="0.01" style={{ flex: '3', border: '1px solid lightgray', borderRadius: 5, width: 100 }} />
        </div>
    );
}
