import { useEffect } from "react";
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { GenericTable } from "../../utils/tables/genericTable";
import { locale, staticTranslate } from "../../../utils/translation";
import { SelectorButton } from "../../utils/buttons/selectorButton";
import { EditableLocaleDisplay } from "../../utils/display/editableLocaleDisplay";
import JustGoService from "../../../api-client/JustGoService";
import { CategoryInterface } from "../../../redux-store/interfaces/AllCategoriesInterface";
import { getAllCategoriesRequestAction, getAllCategoriesSuccessAction } from "../../../redux-store/reducer/allCategoriesSlice";
import { categoryKey, categoryValues } from "../../../api-client/api-schemas/category";
import { ImageDisplay } from "../../utils/display/imageDisplay";
import { SVGDisplay } from "../../utils/display/svgDisplay";

// TODO: Implement selection mechanism
// TODO: Stylize and gather common styles into shared folders
export const CategoriesBody = () => {
    const dispatch = useDispatch();
    const settingsState = useSelector((state: any) => state.settings);
    const authTokenState = useSelector((state: any) => state.authToken);
    const allCategoriesState = useSelector((state: any) => state.allCategories);

    useEffect(() => {
        dispatch(getAllCategoriesRequestAction({ token: authTokenState.authToken } as any));
    }, [authTokenState]);

    const setCategoriesHandler = (content: CategoryInterface[]) => {
        dispatch(getAllCategoriesSuccessAction(content));
    }

    return (
        <div>
            {allCategoriesState.categories.length > 0 && <GenericTable<categoryKey, categoryValues>
                entriesPerPage={20}
                refreshAction={() => dispatch(getAllCategoriesRequestAction({ token: authTokenState.authToken } as any))}
                initialSchemaOrdering={[
                    {
                        schemaName: "image_key", displayName: "Image",
                        isShowing: true,
                        render: (value: string, id: number) => <ImageDisplay imageKey={value} />,
                        disableSorting: true,
                    },
                    {
                        schemaName: "svg_key", displayName: "SVG",
                        isShowing: true,
                        render: (value: string, id: number) => <SVGDisplay svgKey={value} />,
                        disableSorting: true,
                    },
                    {
                        schemaName: "name", displayName: "Name",
                        isShowing: true, isLocale: true,
                        sortKey: (value: locale) => staticTranslate(value, settingsState.settings.language) ?? "",
                        render: (value: locale, id: number) => <EditableLocaleDisplay value={value} id={id} allowEditMode={true}
                            onSubmitEdit={async (id: number, value: locale) => await JustGoService.updateCategory({
                                token: authTokenState.authToken,
                                id: id ?? 0,
                                name: value,
                            })} />,
                    },
                    {
                        schemaName: "id", displayName: "Select",
                        isShowing: true,
                        sortKey: (value: number) => value ?? 0,
                        render: (value: number, id: number) => <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <SelectorButton parentId={id}
                                globalSelection={[]}
                                toggleSelect={() => { }} />
                        </div>
                    },
                ]}
                content={allCategoriesState.categories}
                selection={[]}
                setContentAction={setCategoriesHandler}
            />}
        </div>
    )
}
