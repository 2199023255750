import { useEffect } from "react";
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { getAllCategoriesRequestAction } from "../../../redux-store/reducer/allCategoriesSlice";
import { categoryKey, categoryValues } from "../../../api-client/api-schemas/category";
import { CategoryInterface } from "../../../redux-store/interfaces/AllCategoriesInterface";
import { GenericTable } from "../../utils/tables/genericTable";
import { locale, staticTranslate } from "../../../utils/translation";
import { SelectorButton } from "../../utils/buttons/selectorButton";
import { EditableLocaleDisplay } from "../../utils/display/editableLocaleDisplay";
import JustGoService from "../../../api-client/JustGoService";
import { SubcategoryInterface } from "../../../redux-store/interfaces/AllSubcategoriesInterface";
import { getAllSubcategoriesRequestAction, getAllSubcategoriesSuccessAction } from "../../../redux-store/reducer/allSubcategoriesSlice";
import { EditableIndexedContentDisplay } from "../../utils/display/editableIndexedContentDisplay";

// TODO: Implement selection mechanism
// TODO: Implement better filtering for GenericTable
// TODO: Stylize and gather common styles into shared folders
export const SubcategoriesBody = () => {
    const dispatch = useDispatch();
    const settingsState = useSelector((state: any) => state.settings);
    const authTokenState = useSelector((state: any) => state.authToken);
    const allCategoriesState = useSelector((state: any) => state.allCategories);
    const allSubcategoriesState = useSelector((state: any) => state.allSubcategories);

    useEffect(() => {
        dispatch(getAllSubcategoriesRequestAction({ token: authTokenState.authToken } as any));
    }, [authTokenState]);

    const setSubcategoriesHandler = (content: SubcategoryInterface[]) => {
        dispatch(getAllSubcategoriesSuccessAction(content));
    }

    const indexedCategoryNames = allCategoriesState.indexedCategories
        .map((category: CategoryInterface | null) => (category ? {
            index: category.id,
            value: category.name,
        } : null));

    return (
        <div>
            {allSubcategoriesState.subcategories.length > 0 && <GenericTable<categoryKey, categoryValues>
                entriesPerPage={20}
                refreshAction={() => dispatch(getAllCategoriesRequestAction({ token: authTokenState.authToken } as any))}
                initialSchemaOrdering={[
                    {
                        schemaName: "name", displayName: "Name",
                        isShowing: true, isLocale: true,
                        sortKey: (value: locale) => staticTranslate(value, settingsState.settings.language) ?? "",
                        render: (value: locale, id: number) => <EditableLocaleDisplay value={value} id={id} allowEditMode={true}
                            onSubmitEdit={async (id: number, value: locale) => await JustGoService.updateSubcategory({
                                token: authTokenState.authToken,
                                id: id ?? 0,
                                name: value,
                            })} />,
                    },
                    {
                        schemaName: "category_id", displayName: "Category",
                        isShowing: true,
                        // Here, id refers to the subcategory id, and value refers to its category id, to clear up any confusion.
                        sortKey: (value: number) => allCategoriesState.indexedCategories[value]!.ordering,
                        render: (value: number, id: number) => <EditableIndexedContentDisplay
                            parentId={id}
                            initialIndex={value}
                            indexedLocaleValues={indexedCategoryNames}
                            filter={(content: any) => true} // TODO: Implement a global category/subcategory filter state to filter for subcategories which match a category ID.
                            onSubmitEdit={async (id: number, value: number) => await JustGoService.updateSubcategory({
                                token: authTokenState.authToken,
                                id: id ?? 0,
                                category_id: value,
                            })} />,
                    },
                    {
                        schemaName: "id", displayName: "Select",
                        isShowing: true,
                        sortKey: (value: number) => value ?? 0,
                        render: (value: number, id: number) => <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <SelectorButton parentId={id}
                                globalSelection={[]}
                                toggleSelect={() => { }} />
                        </div>
                    },
                ]}
                content={allSubcategoriesState.subcategories}
                selection={[]}
                setContentAction={setSubcategoriesHandler}
            />}
        </div>
    )
}
