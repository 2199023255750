export type nameSearchFilterProps = {
    name: string;
    state: string;
    inputHandler: (state: string) => void;
}

// TODO: Stylize and gather common styles into shared folders
export const NameSearchFilter = (props: nameSearchFilterProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const inputValue = e.target.value.toLowerCase(); // Attempt to convert input value to lowercase
            props.inputHandler(inputValue);
        } catch (error) {
            console.error("Error converting input value to lowercase:", error);
            props.inputHandler(""); // Fallback to default empty string in case of error
        }
    };

    return (
        <div>
            <input
                type="text"
                placeholder="Search by name..."
                onChange={handleChange}
            />
        </div>
    )
}

