import { GenericTable } from "../../utils/tables/genericTable";
import { itemKey, itemValues } from "../../../api-client/api-schemas/item";
import { useDispatch, useSelector } from "react-redux";
import { getAllItemsRequestAction, getAllItemsSuccessAction } from "../../../redux-store/reducer/allItemsSlice";
import { useEffect } from "react";
import { locale, staticTranslate as extractLanguage } from "../../../utils/translation";
import { ImageDisplay } from "../../utils/display/imageDisplay";
import { SkuDisplay } from "../../utils/display/skuDisplay";
import { EditableNumberDisplay } from "../../utils/display/editableNumberDisplay";
import JustGoService from "../../../api-client/JustGoService";
import { EditableStringDisplay } from "../../utils/display/editableStringDisplay";
import { EditableLocaleDisplay } from "../../utils/display/editableLocaleDisplay";
import { EditableIndexedContentDisplay } from "../../utils/display/editableIndexedContentDisplay";

import { SubcategoryInterface } from "../../../redux-store/interfaces/AllSubcategoriesInterface";
import { SelectorButton } from "../../utils/buttons/selectorButton";

import {
    selectItemByIdAction
} from "../../../redux-store/reducer/allItemsSlice";

// TODO: Implement selection mechanism
// TODO: Stylize and gather common styles into shared folders
export const ItemsBody = () => {
    const dispatch = useDispatch();
    const settingsState = useSelector((state: any) => state.settings);
    const authTokenState = useSelector((state: any) => state.authToken);
    const allItemsState = useSelector((state: any) => state.allItems);
    const allSubcategoriesState = useSelector((state: any) => state.allSubcategories);
    const clipboardState = useSelector((state: any) => state.clipboard);

    const indexedSubcategoryNames = allSubcategoriesState.indexedSubcategories
        .map((subcategory: SubcategoryInterface | null) => (subcategory ? {
            index: subcategory.id,
            value: subcategory.name,
        } : null));

    useEffect(() => {
        dispatch(getAllItemsRequestAction({ token: authTokenState.authToken } as any));
    }, [authTokenState]);

    const setItemsHandler = (content: (itemKey & itemValues)[]) => {
        dispatch(getAllItemsSuccessAction(content));
    }

    return (
        <div>
            {allItemsState.items.length > 0 && <GenericTable<itemKey, itemValues>
                entriesPerPage={allItemsState.pageSize}
                refreshAction={() => dispatch(getAllItemsRequestAction({ token: authTokenState.authToken } as any))}
                initialSchemaOrdering={[
                    {
                        schemaName: "image_key", displayName: "Image",
                        isShowing: true,
                        render: (value: string, id: number) => <ImageDisplay imageKey={value} />,
                        disableSorting: true,
                    },
                    {
                        schemaName: "sku", displayName: "SKU",
                        isShowing: true,
                        render: (value: string, id: number) => <SkuDisplay sku={value} />,
                        disableSorting: true,
                    },
                    {
                        schemaName: "shelf_number", displayName: "Shelf Number",
                        isShowing: true,
                        disableSorting: true,
                        render: (value: string, id: number) => <EditableStringDisplay value={value} id={id} allowEditMode={true}
                            onSubmitEdit={async (id: number, value: string) => await JustGoService.updateItem({
                                token: authTokenState.authToken,
                                id: id ?? 0,
                                shelf_number: value,
                            })} />,
                    },
                    {
                        schemaName: "name", displayName: "Name",
                        isShowing: true, isLocale: true,
                        sortKey: (value: locale) => extractLanguage(value, settingsState.settings.language) ?? "",
                        render: (value: locale, id: number) => <EditableLocaleDisplay value={value} id={id} allowEditMode={true}
                            onSubmitEdit={async (id: number, value: locale) => await JustGoService.updateItem({
                                token: authTokenState.authToken,
                                id: id ?? 0,
                                name: value,
                            })} />,
                    },
                    {
                        schemaName: "subcategory_id", displayName: "Subcategory",
                        isShowing: true,
                        // Here, id refers to the item id, and value refers to its subcategory id, to clear up any confusion.
                        sortKey: (value: number) => extractLanguage(allSubcategoriesState.indexedSubcategories[value]!.name, settingsState.settings.language),
                        render: (value: number, id: number) => <EditableIndexedContentDisplay
                            parentId={id}
                            initialIndex={value}
                            indexedLocaleValues={indexedSubcategoryNames}
                            filter={(content: any) => true} // TODO: Implement a global category/subcategory filter state to filter for subcategories which match a category ID.
                            onSubmitEdit={async (id: number, value: number) => await JustGoService.updateItem({
                                token: authTokenState.authToken,
                                id: id ?? 0,
                                subcategory_id: value,
                            })} />,
                    },
                    {
                        schemaName: "theoretical_price", displayName: "Base Price",
                        isShowing: true,
                        sortKey: (value: number) => value ?? 0,
                        render: (value: number, id: number) => <EditableNumberDisplay value={value ?? 0} allowEditMode={true}
                            id={id}
                            onSubmitEdit={async (id: number, value: number) => await JustGoService.updateItem({
                                token: authTokenState.authToken,
                                id: id ?? 0,
                                theoretical_price: value,
                            })} />
                    },
                    {
                        schemaName: "price", displayName: "Current Price",
                        isShowing: true,
                        sortKey: (value: number) => value ?? 0,
                        render: (value: number, id: number) => <EditableNumberDisplay value={value ?? 0} id={id} allowEditMode={true}
                            onSubmitEdit={async (id: number, value: number) => await JustGoService.updateItem({
                                token: authTokenState.authToken,
                                id: id ?? 0,
                                price: value,
                            })} />
                    },
                    {
                        schemaName: "low_stock_threshold", displayName: "Low Stock Threshold",
                        isShowing: true,
                        sortKey: (value: number) => value ?? 0,
                        render: (value: number, id: number) => <EditableNumberDisplay value={value} id={id} allowEditMode={true}
                            onSubmitEdit={async (id: number, value: number) => await JustGoService.updateItem({
                                token: authTokenState.authToken,
                                id: id ?? 0,
                                low_stock_threshold: value,
                            })} />
                    },
                    {
                        schemaName: "id", displayName: "Select",
                        isShowing: true,
                        sortKey: (value: number) => value ?? 0,
                        render: (value: number, id: number) => <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <SelectorButton parentId={id}
                                globalSelection={allItemsState.globalSelection[id]}
                                toggleSelect={() => {
                                    dispatch(selectItemByIdAction(id));
                                    console.log(allItemsState.globalSelection.filter((selected: boolean, index: number) => selected).map((selected: boolean, index: number) => index));
                                }} />
                        </div>
                    },
                ]}
                content={allItemsState.items}
                selection={clipboardState.selectedItems}
                setContentAction={setItemsHandler}
            />}
        </div>
    )
}
