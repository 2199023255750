import { modalConfigMap } from "./navigation";
import { CreateItemModal } from "../../components/modals/item/CreateItemModal";
import { DeleteSelectedItemsModal } from "../../components/modals/item/DeleteSelectedItemsModal";
import { GetSelectedItemDetailsModal } from "../../components/modals/item/GetSelectedItemDetailsModal";
import { UpdatedSelectedItemModal } from "../../components/modals/item/UpdateSelectedItemModal";
import { ReorderCategories } from "../../components/modals/reordering/ReorderCategoriesModal";
import { ReorderSubcategories } from "../../components/modals/reordering/ReorderSubcategoriesModal";

export const modalConfig: modalConfigMap = {
    CREATE_ITEM: { 
        displayName: "Create Item", 
        modalElement: <CreateItemModal />, 
        cancellable: true 
    },
    UPDATE_ITEM: { 
        displayName: "Update Item", 
        modalElement: <UpdatedSelectedItemModal />, 
        cancellable: true 
    },
    GET_ITEM_DETAILS: { 
        displayName: "Get Item Details", 
        modalElement: <GetSelectedItemDetailsModal />, 
        cancellable: true 
    },
    DELETE_ITEM: { 
        displayName: "Delete Item", 
        modalElement: <DeleteSelectedItemsModal />, 
        cancellable: true 
    },
    REORDER_CATEGORIES: { 
        displayName: "Reorder Categories", 
        modalElement: <ReorderCategories />, 
        cancellable: true 
    },
    REORDER_SUBCATEGORIES: { 
        displayName: "Reorder Subcategories", 
        modalElement: <ReorderSubcategories />, 
        cancellable: true 
    },
}
