import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, Popup, Circle, CircleMarker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliverableOrdersRequestAction } from '../../../redux-store/reducer/ordersSlice';
import { getAssignableOrderDeliveriesRequestAction } from '../../../redux-store/reducer/orderDeliveriesSlice';
import { orderDeliveryKey, orderDeliveryValues } from '../../../api-client/api-schemas/orderDelivery';
import ErrorBoundary from '../../utils/error/errorBoundary';

const CENTER_LAT = 33.633681758009224;
const CENTER_LONG = -117.71486357643326;
const INIT_BOUND = 0.15;

export enum WaypointType {
    PENDING,
    URGENT,
    EXPIRED,
    HOME,
}

export type Waypoint = {
    type: WaypointType;
    lat: number;
    lng: number;
    orderId: number;
}

export const MapBody = () => {
    const [waypoints, setWaypoints] = useState<Waypoint[]>([
        { type: WaypointType.HOME, lat: CENTER_LAT, lng: CENTER_LONG, orderId: 0 },
        // TODO: Redux-provide the other order deliveries via API
    ]);
    const dispatch = useDispatch();
    const authTokenState = useSelector((state: any) => state.authToken);
    const orderDeliveriesState = useSelector((state: any) => state.orderDeliveries);
    const ordersState = useSelector((state: any) => state.orders);
    const assignableOrderDeliveries: Waypoint[] = [];
    
    orderDeliveriesState.orderDeliveries.order_deliveries?.forEach((orderDelivery: orderDeliveryKey & orderDeliveryValues, index: number) => {
        const order = ordersState.indexedOrders[orderDelivery.order_id] ?? null;
        if (order && order.lat != null && order.long != null) {
            assignableOrderDeliveries.push({
                type: WaypointType.PENDING,
                lat: order.lat,
                lng: order.long,
                orderId: orderDelivery.order_id,
            });
        }
    });

    useEffect(() => {
        dispatch(getDeliverableOrdersRequestAction({ token: authTokenState.authToken } as any));
        dispatch(getAssignableOrderDeliveriesRequestAction({ token: authTokenState.authToken } as any));
    }, [authTokenState]);

    return (

        <div style={{ height: 1200, width: '100%' }}>
            {/* <div>
                {JSON.stringify(assignableOrderDeliveries)}
            </div> */}
            <MapContainer 
                bounds={[
                    [CENTER_LAT - INIT_BOUND, CENTER_LONG - INIT_BOUND], 
                    [CENTER_LAT + INIT_BOUND, CENTER_LONG + INIT_BOUND]
                ]} 
                style={{ height: '100%', width: '100%' }}
            >
                <TileLayer
                    // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {(waypoints.concat(assignableOrderDeliveries ?? [])).map((waypoint) => (
                    <ErrorBoundary>
                        <CircleMarker center={[waypoint.lat, waypoint.lng]}>
                            <div>
                                {waypoint?.orderId ?? ""}
                            </div>
                            <Tooltip>
                                <div style={{
                                    fontSize: 30,
                                }}>
                                    {waypoint.type === WaypointType.HOME ? "\u{1F3E0}" : ((waypoint?.orderId % 10000) ?? "")}
                                </div>
                            </Tooltip>
                        </CircleMarker>
                    </ErrorBoundary>
                ))}
            </MapContainer>
        </div>
    );
}

