import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColumnHeader, column } from "./columnHeader";
import { ActionButton, ActionColor } from "../buttons/actionButton";
import { setSelectedItems } from "../../../redux-store/reducer/clipboardSlice";
import { locale, staticTranslate } from "../../../utils/translation";
import { CategorySearchFilter } from "../filters/categorySearchFilter";

export type tableProps<keySchema extends Record<string, any>, valueSchema> = {
    entriesPerPage: number,
    initialSchemaOrdering: column[],
    content: Partial<(keySchema & valueSchema)>[],
    selection: Partial<(keySchema & valueSchema)>[],

    setContentAction: (content: any[]) => void,
    refreshAction: () => void,
    // paginatedContent: Partial<(keySchema & valueSchema)>[][],
    // TODO: Find a better way to deal with paginated content; slice operations in Typescript are O(N)
    // which gets in the way. Sorting/filtering will likely have to be handled server-side in a better API.
}

// TODO: Use the new sorting keys to perform the sort rather than on the manual one.
// TODO: Stylize and gather common styles into shared folder
export const GenericTable = <keySchema extends Record<string, any>, valueSchema extends Record<string, any>>(props: tableProps<keySchema, valueSchema>) => {
    const dispatch = useDispatch();
    const settingsState = useSelector((state: any) => state.settings);

    // Table/Page Navigation & Rearranging
    const [page, setPage] = useState(0);
    const [schemaOrdering, setSchemaOrdering] = useState<column[]>(props.initialSchemaOrdering);
    const [columnWidths, setColumnWidths] = useState<Record<string, number>>({});

    // Selection
    const [filteredContent, setFilteredContent] = useState<Partial<(keySchema & valueSchema)>[]>(props.content ?? []);

    const incrementPage = () => {
        setPage(page + 1);
    }
    const decrementPage = () => {
        setPage(page - 1);
    }
    const swapOrderingLeft = (index: number) => {
        if (index < 0 || index >= schemaOrdering.length - 1) return; // Prevent out-of-bounds access
        const newOrdering = [...schemaOrdering];
        const temp = newOrdering[index];
        newOrdering[index] = newOrdering[index + 1];
        newOrdering[index + 1] = temp;
        setSchemaOrdering(newOrdering);
    }
    const swapOrderingRight = (index: number) => {
        if (index <= 0 || index >= schemaOrdering.length) return; // Prevent out-of-bounds access
        const newOrdering = [...schemaOrdering];
        const temp = newOrdering[index];
        newOrdering[index] = newOrdering[index - 1];
        newOrdering[index - 1] = temp;
        setSchemaOrdering(newOrdering);
    }
    const resetOrderingAndSize = () => {
        setSchemaOrdering(props.initialSchemaOrdering);
        setColumnWidths({});
    }
    const startResize = (e: React.MouseEvent<HTMLDivElement>, schemaName: string, direction: 'left' | 'right') => {
        const startX = e.clientX;
        const startWidth = columnWidths[schemaName] || e.currentTarget.offsetWidth;
        const handleMouseMove = (moveEvent: MouseEvent) => {
            let currentWidth: number;
            if (direction === 'right') {
                currentWidth = startWidth + (moveEvent.clientX - startX);
            } else {
                currentWidth = startWidth - (moveEvent.clientX - startX);
            }
            setColumnWidths(prevWidths => ({ ...prevWidths, [schemaName]: Math.max(currentWidth, 20) })); // Adjusted minimum column width to 20px
        };
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const sortByAttribute = (schemaName: string, useLocale?: boolean, direction: 'asc' | 'desc' = 'asc') => {
        const sortedItems = [...filteredContent].sort((a_, b_) => {
            let aValue, bValue;
            if (useLocale) {
                aValue = staticTranslate(a_[schemaName] as locale, settingsState.settings.language);
                bValue = staticTranslate(b_[schemaName] as locale, settingsState.settings.language);
            } else {
                aValue = a_[schemaName] ?? 0;
                bValue = b_[schemaName] ?? 0;
            }
            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setFilteredContent(sortedItems);
        setPage(0);
    }

    const columnSchemaMap = new Map<string, column>();
    schemaOrdering.forEach(column => {
        columnSchemaMap.set(column.schemaName, column);
    });
    return (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0', width: '100%' }}>
                        <ActionButton name="Reset"
                            actionColor={ActionColor.SUBMIT}
                            activatedOn={() => true}
                            clickHandler={resetOrderingAndSize}
                        />
                        <ActionButton name="Refresh"
                            actionColor={ActionColor.SUBMIT}
                            activatedOn={() => true}
                            clickHandler={props.refreshAction}
                        />
                        <ActionButton
                            name="Previous"
                            actionColor={ActionColor.SUBMIT}
                            activatedOn={() => page > 0}
                            clickHandler={decrementPage}
                        />
                        <span>Page {page + 1}</span>
                        <ActionButton
                            name="Next"
                            actionColor={ActionColor.SUBMIT}
                            activatedOn={() => page < Math.ceil(filteredContent.length / props.entriesPerPage) - 1}
                            clickHandler={incrementPage}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <CategorySearchFilter
                        content={props.content}
                        filterHandler={setFilteredContent}
                    />
                    <ActionButton name="Select All"
                        actionColor={ActionColor.SUBMIT}
                        activatedOn={() => true}
                        clickHandler={() => dispatch(setSelectedItems([...filteredContent]))}
                    />
                    <ActionButton name="Deselect All"
                        actionColor={ActionColor.SUBMIT}
                        activatedOn={() => true}
                        clickHandler={() => dispatch(setSelectedItems([]))}
                    />
                    <ActionButton name="Show Only Selected"
                        actionColor={ActionColor.SUBMIT}
                        activatedOn={() => true}
                        clickHandler={() => setFilteredContent(props.selection)}
                    />
                </div>
            </div>
            <table style={{ borderCollapse: 'collapse', width: '100%', borderSpacing: '0' }}>
                <thead style={{ backgroundColor: 'lightgray', width: '100%' }}>
                    <tr>
                        {schemaOrdering.map(({ schemaName }, i) => {
                            const index = i + 0;
                            return (<th key={schemaName} style={{
                                width: columnWidths[schemaName] ? `${columnWidths[schemaName]}px` : 'auto',
                                borderRight: '1px solid #D3D3D3',
                            }}>
                                <div style={{ width: '100%', height: '100%' }} onMouseDown={(e) => e.stopPropagation()}>
                                    <ColumnHeader
                                        index={index}
                                        column={columnSchemaMap.get(schemaName)!}
                                        resizeLeftHandler={(e) => startResize(e, schemaName, 'left')}
                                        resizeRightHandler={(e) => startResize(e, schemaName, 'right')}
                                        reorderLeftHandler={(e) => swapOrderingRight(index)}
                                        reorderRightHandler={(e) => swapOrderingLeft(index)}
                                        sortHandler={(isAscending) => sortByAttribute(schemaName, columnSchemaMap.get(schemaName)?.isLocale, isAscending ? 'asc' : 'desc')}
                                    />
                                </div>
                            </th>)
                        }
                        )}
                    </tr>
                </thead>
                <tbody>
                    {filteredContent && filteredContent.slice(page * props.entriesPerPage, (page + 1) * props.entriesPerPage).map((item, index) => (
                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f8f8f8' }}>
                            {schemaOrdering.map(({ schemaName }, valueIndex) => (
                                <td key={valueIndex} style={{
                                    width: columnWidths[schemaName] ? `${columnWidths[schemaName]}px` : 'auto',
                                    maxWidth: columnWidths[schemaName],
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    borderRight: '1px solid #D3D3D3',
                                }}>
                                    {columnSchemaMap.get(schemaName)?.render ?
                                        columnSchemaMap.get(schemaName)?.render!(item[schemaName], item.id!) :
                                        String(item[schemaName])
                                    }
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}
