import { useState } from "react";
import { deleteItemRequest } from "../../../api-client/api-schemas/item";
import { ErrorDisplay } from "../../utils/error/errorDisplay";
import JustGoService, { authentication } from "../../../api-client/JustGoService";
import { useDispatch, useSelector } from "react-redux";
import { updateNavigation } from "../../../redux-store/reducer/navigationSlice";
import { ActionButton, ActionColor } from "../../utils/buttons/actionButton";

// TODO: Stylize and gather common styles into shared folder
export const DeleteSelectedItemsModal = (props: any) => {

    const dispatch = useDispatch();

    const authTokenState = useSelector((state: any) => state.authToken);
    const clipboardState = useSelector((state: any) => state.clipboard);
    const selectedItems = clipboardState.selectedItems;

    const [error, setError] = useState<Error | null>(null);
    const submitHandler = async () => {
        try {
            for (const item of selectedItems) {
                const response = await JustGoService.deleteItem({
                    id: item.id,
                    token: authTokenState.authToken,
                } as deleteItemRequest & authentication);
            }
            setError(null);
            dispatch(updateNavigation({ selectedModal: null }));
        } catch (error) {
            setError(error as Error);
        }
    }

    const cancelHandler = async () => {
        dispatch(updateNavigation({ selectedModal: null }));
    }

    return (<div>
        <h2>Delete Item</h2>
        {error && <ErrorDisplay error={error} />}
        <div style={{
            margin: '10px'
        }}>
            <h2>Are you sure you want to delete the selected items? </h2>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <ActionButton name={"Submit"}
                actionColor={ActionColor.SUBMIT}
                activatedOn={() => true} // TODO: Validate
                clickHandler={submitHandler} />
            <ActionButton name={"Cancel"}
                actionColor={ActionColor.CANCEL}
                activatedOn={() => true}
                clickHandler={cancelHandler} />
        </div>
    </div>);
}