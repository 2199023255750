import { useSelector } from "react-redux";
import { DropdownField } from "../form/dropdownField";
import { useState } from "react";
import { NameSearchFilter } from "./nameSearchFilter";
import { staticTranslate } from "../../../utils/translation";

export type CategorySearchFilterProps = {
    content: any[];
    filterHandler: (filteredContent: any[]) => void;
}

// TODO: When HierarchyDropdownFilter is imnplemented, wrap this component around that 
// TODO: Stylize and gather common styles into shared folders
export const CategorySearchFilter = (props: CategorySearchFilterProps) => {

    const settingsState = useSelector((state: any) => state.settings);
    const categoriesState = useSelector((state: any) => state.allCategories);
    const subcategoriesState = useSelector((state: any) => state.allSubcategories);

    const clipboardState = useSelector((state: any) => state.clipboard);
    const selectedItem = (clipboardState.selectedItems && clipboardState.selectedItems.length == 1) ? clipboardState.selectedItems[0] : null;
    const [subcategoryId, setSubcategoryId] = useState<number>(selectedItem?.subcategory_id ?? 0);
    const selectedCategory = categoriesState.categories.find((category: any) => category.id === (subcategoriesState.subcategories.find((sub: any) => sub.id === subcategoryId)?.category_id));
    const [categoryId, setCategoryId] = useState<number>(selectedCategory?.id ?? 0);

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [categoryFilteredContent, setCategoryFilteredContent] = useState<any[]>(props.content);

    const getAvailableSubcategories = (categoryId: number) => {
        return subcategoriesState ? subcategoriesState.subcategories
            .filter((subcategory: any) => subcategory.category_id === categoryId)
            .map((subcategory: any) => {
                return {
                    id: subcategory.id,
                    name: subcategory.name,
                }
            }) : [];
    }
    const getAvailableSubcategoryIDs = (categoryId: number) => {
        return getAvailableSubcategories(categoryId).map((subcategory: any) => subcategory.id);
    }
    const searchCriteria = (localeName: string) => {
        return localeName.toLowerCase().includes(searchTerm.toLowerCase());
    }


    const filterContentByCategory = (categoryId: number, subcategoryIds: number[]) => {
        if (categoryId === 0) {
            props.filterHandler(props.content);
            return;
        }
        const filtered = props.content.filter((item: any) =>
            subcategoryIds.includes(item.subcategory_id)
        );
        props.filterHandler(filtered);
        setCategoryFilteredContent(filtered);
    }
    const filterContentBySubcategory = (categoryId: number, subcategoryId: number) => {
        if (subcategoryId === 0) {
            props.filterHandler(props.content);
            return;
        }
        const filtered = props.content.filter((item: any) => item.subcategory_id === subcategoryId);
        props.filterHandler(filtered);
        setCategoryFilteredContent(filtered);
    }
    const filterContentBySearchTerm = (searchTerm: string) => {
        if (searchTerm === '') {
            props.filterHandler(categoryFilteredContent);
            return;
        }
        const filtered = categoryFilteredContent.filter((item: any) => {
            const localeName = staticTranslate(item.name, settingsState.settings.language);
            return searchCriteria(localeName);
        });
        props.filterHandler(filtered);
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                    <DropdownField name="Category" state={categoryId}
                        options={[{ id: 0, name: '' }, ...(categoriesState ? categoriesState.categories.map((category: any) => {
                            return {
                                id: category.id,
                                name: category.name,
                            }
                        }) : [])]}
                        inputHandler={(input: number) => {
                            setCategoryId(input);
                            filterContentByCategory(input, getAvailableSubcategoryIDs(input))
                        }} />
                    <button style={{ marginLeft: '5px' }} onClick={() => {
                        setCategoryId(0);
                        setSubcategoryId(0);
                        filterContentByCategory(0, [])
                    }}>
                        Reset
                    </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DropdownField name="Subcategory" state={subcategoryId}
                        options={[{ id: 0, name: '' }, ...getAvailableSubcategories(categoryId)]}
                        inputHandler={(input: number) => {
                            if (input === 0) {
                                setSubcategoryId(0);
                                filterContentByCategory(categoryId, getAvailableSubcategoryIDs(categoryId));
                                return;
                            }
                            setSubcategoryId(input);
                            filterContentBySubcategory(categoryId, input)
                        }} />
                    <button style={{ marginLeft: '5px' }} onClick={() => {
                        setSubcategoryId(0);
                        filterContentByCategory(categoryId, getAvailableSubcategoryIDs(categoryId))
                    }}>
                        Reset
                    </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <NameSearchFilter name="Search" state={searchTerm}
                        inputHandler={(input: string) => {
                            setSearchTerm(input);
                            filterContentBySearchTerm(input);
                        }} />
                    <button style={{ marginLeft: '5px' }} onClick={() => {
                        setSearchTerm('');
                        filterContentBySearchTerm('');
                    }}>
                        Search
                    </button>
                </div>
            </div>
        </div>
    )
}
