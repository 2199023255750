import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import AppReduxStore from './redux-store/AppReduxStore';
import {BrowserRouter} from 'react-router-dom';
import { setAuthTokenSuccessAction } from './redux-store/reducer/authTokenSlice';
import store from './redux-store/AppReduxStore'; // Adjust the import path according to your project structure
import {enableMapSet} from 'immer';

const tokenWithExpiry = localStorage.getItem('authToken');
  if (tokenWithExpiry) {
    const { token, expiry } = JSON.parse(tokenWithExpiry);
    if (new Date().getTime() < expiry) {
      store.dispatch(setAuthTokenSuccessAction(token));
    } else {
      localStorage.removeItem('authToken'); // Token expired, remove it
    }
  }

  enableMapSet();
  

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={AppReduxStore}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();