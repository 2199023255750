import { useEffect, useState } from "react"
import { ErrorDisplay } from "../error/errorDisplay";
import { ActionButton, ActionColor } from "../buttons/actionButton";

export type numberDisplayProps = {
    value: number,
    id?: number,
    allowEditMode?: boolean,
    onSubmitEdit?: (id: number, value: number) => Promise<any>,
}

// TODO: Stylize and gather common styles into shared folders
export const EditableNumberDisplay = (props: numberDisplayProps) => {
    const [editMode, setEditMode] = useState(false);
    const [editValue, setEditValue] = useState<string>((props.value ?? 0).toString());
    const [currentValue, setCurrentValue] = useState((props.value ?? 0));
    const [valueHistory, setValueHistory] = useState<number[]>([props.value ?? 0]);
    const [error, setError] = useState<Error | null>(null);

    const initEditModeHandler = () => {
        setEditMode(true);
    }
    const submitHandler = async () => {
        if (props.onSubmitEdit) {
            try {
                const parsedEditValue = parseFloat(editValue) || 0;
                if (parsedEditValue !== props.value) {
                    await props.onSubmitEdit(props.id ?? 0, parsedEditValue);
                    setValueHistory([...valueHistory, parsedEditValue]);
                    setCurrentValue(parsedEditValue);
                }
                setError(null);
                setEditMode(false);
            } catch (error) {
                setError(error as Error);
            }
        } else {
            setCurrentValue(props.value);
        }
    }
    const undoHandler = async () => {
        if (props.onSubmitEdit && valueHistory.length > 1) {
            try {
                const previousValue = valueHistory[valueHistory.length - 2];
                await props.onSubmitEdit(props.id ?? 0, previousValue);
                setCurrentValue(previousValue);
                setValueHistory(valueHistory.slice(0, valueHistory.length - 1));
                setError(null);
                setEditMode(false);
            } catch (error) {
                setError(error as Error);
            }
        } else {
            setCurrentValue(props.value);
        }
    }
    const cancelHandler = () => {
        setEditMode(false);
        setEditValue(currentValue.toString());
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditValue(e.target.value);
    }
    useEffect(() => {
        setEditValue(props.value ? props.value.toString() : '');
        setCurrentValue(props.value ?? 0);
    }, [props.id, props.value]);

    const valueStyle = {
        padding: 1,
        borderRadius: 5,
        border: editMode ? '1px solid gray' : '1px solid transparent',
        paddingRight: 5,
        textOverflow: 'ellipsis',
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {error && <ErrorDisplay error={error} />}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', marginLeft: 10, }}>
                {editMode ? (
                    <>
                        <ActionButton mini={true} name="Submit" actionColor={ActionColor.SUBMIT} clickHandler={submitHandler} activatedOn={() => !!(props.value || editMode) && !isNaN(parseFloat(editValue))} />
                        <ActionButton mini={true} name="Cancel" actionColor={ActionColor.CANCEL} clickHandler={cancelHandler} activatedOn={() => true} />
                    </>
                ) : (
                    <>
                        <ActionButton mini={true} name="Edit" actionColor={ActionColor.SUBMIT} clickHandler={initEditModeHandler} activatedOn={() => true} />
                        {valueHistory.length > 1 && <ActionButton mini={true} name="Undo" actionColor={ActionColor.SUBMIT} clickHandler={undoHandler} activatedOn={() => true} />}
                    </>
                )}
            </div>
            <div style={{ minWidth: '100px', margin: '2px 20px 2px 20px', marginRight: 20, display: 'flex', alignItems: 'center', width: '100%' }}>
                {editMode ? (
                    <input
                        type="text"
                        value={editValue}
                        onChange={handleInputChange}
                        style={{ ...valueStyle, width: '100%', textAlign: 'left' }}
                    />
                ) : (
                    <div style={{ ...valueStyle, opacity: currentValue ? '1' : '0.25', width: '100%', display: 'flex', alignItems: 'center' }}>
                        {currentValue}
                    </div>
                )}
            </div>
        </div>
    );
}
