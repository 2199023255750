import {configureStore} from '@reduxjs/toolkit';
import rootReducer from "./rootReducer";
import {createEpicMiddleware} from "redux-observable";
import {rootEpic} from "./rootEpic";

const epicMiddleware = createEpicMiddleware();

const AppReduxStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({            
        serializableCheck: {
            ignoredActions: [
                'allItems/getAllItemsFailedAction',
            ],
            ignoredPaths: [
                'allItems.result.message',
            ],
        },
    }).concat(epicMiddleware)
});

epicMiddleware.run(rootEpic);

export default AppReduxStore;