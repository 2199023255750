import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import { AuthTokenInterface } from '../interfaces/AuthTokenInterface';

const initialState: AuthTokenInterface = {
  token: "",
  isLoading: false,
  isSuccessful: false,
  error: {}
};

const authTokenSlice = createSlice({
  name: 'authToken',
  initialState,
  reducers: {
    setAuthTokenRequestAction: (state: any) => {
      state.isLoading = true;
    },
    setAuthTokenSuccessAction: (state: any, action: PayloadAction<"">) => {
      state.authToken = action.payload;
      state.isLoading = false;
      state.isSuccessful = true;
      const expiry = new Date().getTime() + (60 * 60 * 1000); // 1 hour from now
      localStorage.setItem('authToken', JSON.stringify({ token: action.payload, expiry }));
    },
    setAuthTokenFailedAction: (state: any, action: PayloadAction<{}>) => {
      state.isSuccessful = false;
      state.result = action.payload;
      localStorage.removeItem('authToken'); // Clear token on failure
    },
  },
});

export const { 
    setAuthTokenRequestAction, 
    setAuthTokenSuccessAction,
    setAuthTokenFailedAction,
} = authTokenSlice.actions;
export default authTokenSlice.reducer;
