import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import { OrderDeliveriesInterface, OrderDeliveryInterface } from '../interfaces/OrderDeliveriesInterface';

const initialState: OrderDeliveriesInterface = {
    orderDeliveries: [],
    isLoading: false,
    isSuccessful: false,
    error: {}
}

export const orderDeliveriesSlice = createSlice({
    name: 'orderDeliveries',
    initialState,
    reducers: {
        getAssignableOrderDeliveriesRequestAction: (state: any) => {
            state.isLoading = true;
        },
        getAssignableOrderDeliveriesSuccessAction: (state: any, action: PayloadAction<OrderDeliveryInterface[]>) => {
            state.orderDeliveries = action.payload;
            state.isLoading = false;
            state.isSuccessful = true;
        },
        getAssignableOrderDeliveriesFailedAction: (state: any, action: PayloadAction<{}>) => {
            state.isSuccessful = false;
            state.result = action.payload;
        },
    },
});

export const {
    getAssignableOrderDeliveriesRequestAction,
    getAssignableOrderDeliveriesSuccessAction,
    getAssignableOrderDeliveriesFailedAction,
} = orderDeliveriesSlice.actions;
export default orderDeliveriesSlice.reducer;
