import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import JustGoService from "../../../api-client/JustGoService";
import { ActionButton, ActionColor } from "../../utils/buttons/actionButton";
import { ErrorDisplay } from "../../utils/error/errorDisplay";
import { updateNavigation } from "../../../redux-store/reducer/navigationSlice";
import { DropdownField } from "../../utils/form/dropdownField";
import { SubcategoryInterface } from "../../../redux-store/interfaces/AllSubcategoriesInterface";
import { getAllSubcategoriesRequestAction, setNewSubcategoriesOrder } from "../../../redux-store/reducer/allSubcategoriesSlice";

export function ReorderSubcategories() {
    const authTokenState = useSelector((state: any) => state.authToken);
    const [error, setError] = useState<Error | null>(null);
    const dispatch = useDispatch();

    const allCategoriesState = useSelector((state: any) => state.allCategories);
    const allSubcategoriesState = useSelector((state: any) => state.allSubcategories);
    const initId = allCategoriesState.categories[0].id ?? 0;
    const filteredSubcategories = allSubcategoriesState.subcategories
        .filter((subcategory: SubcategoryInterface) => subcategory.category_id === initId)
        .sort((a: SubcategoryInterface, b: SubcategoryInterface) => a.ordering - b.ordering);
    const [categoryId, setCategoryId] = useState<number>(initId);
    const [currentSubcategories, setCurrentSubcategories] = useState<SubcategoryInterface[]>(filteredSubcategories);
    const [updatedSubcategories, setUpdatedSubcategories] = useState<SubcategoryInterface[]>(filteredSubcategories);

    useEffect(() => {
        dispatch(getAllSubcategoriesRequestAction({ token: authTokenState.authToken } as any));
    }, [dispatch]);

    const handleChangeCategory = (input: number) => {
        const filteredSubcategories = allSubcategoriesState.subcategories
            .filter((subcategory: SubcategoryInterface) => subcategory.category_id === input)
            .sort((a: SubcategoryInterface, b: SubcategoryInterface) => a.ordering - b.ordering);
        setCategoryId(input);
        setCurrentSubcategories(filteredSubcategories);
        setUpdatedSubcategories(filteredSubcategories);
    }

    const handleDragEnd = async (result: any) => {
        const { source, destination } = result;
        if (!destination) { return; }
        const updatedSubcategoriesCopy: SubcategoryInterface[] = Array.from(updatedSubcategories);
        const [movedSubcategory] = updatedSubcategoriesCopy.splice(source.index, 1);
        updatedSubcategoriesCopy.splice(destination.index, 0, movedSubcategory);
        setUpdatedSubcategories(updatedSubcategoriesCopy);
    };

    const handleSubmit = async () => {
        try {
            updatedSubcategories.forEach((subcategory: SubcategoryInterface, index: number) => {
                JustGoService.updateSubcategory({
                    token: authTokenState.authToken,
                    id: subcategory.id,
                    ordering: index,
                });
            });
            dispatch(setNewSubcategoriesOrder(updatedSubcategories));
            dispatch(updateNavigation({
                selectedModal: null
            }));
            setCurrentSubcategories(updatedSubcategories);
            setError(null);
        } catch (error) {
            setError(error as Error);
        }
    }

    const handleExit = () => {
        dispatch(updateNavigation({
            selectedModal: null
        }));
    }

    // TODO: Refactor with hierarchy dropdown field
    return (
        <div style={{ height: '800px', overflowY: 'scroll' }}>
        {error && <ErrorDisplay error={error} />}
        <DropdownField name="Category" state={categoryId}
                options={allCategoriesState ? allCategoriesState.categories.map((category: any) => {
                    return {
                        id: category.id,
                        name: category.name,
                    }
                }) : []}
                inputHandler={handleChangeCategory} />
        <DragDropContext onDragEnd={handleDragEnd}>
            <table className="table-auto">
                <thead>
                    <tr>
                        <th className="px-4 py-2">Name (EN)</th>
                        <th className="px-4 py-2">Name (ZH)</th>
                    </tr>
                </thead>
                <Droppable droppableId="subcategories" type="group" >
                    {(provided) => (
                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                            {updatedSubcategories.map((subcategory: SubcategoryInterface, index: number) => (
                                <Draggable key={subcategory.id} draggableId={subcategory.name.en} index={index}>
                                    {(provided) => (
                                        <tr
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <td className="px-4 py-2">{subcategory.name.en}</td>
                                            <td className="px-4 py-2">{subcategory.name.zh}</td>
                                            {/*<td className="px-4 py-2">{category.ordering}</td>*/}
                                        </tr>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </tbody>
                    )}
                </Droppable>
            </table>
        </DragDropContext>
        <ActionButton name="Save" actionColor={ActionColor.SUBMIT} activatedOn={() => JSON.stringify(updatedSubcategories) !== JSON.stringify(currentSubcategories)} clickHandler={handleSubmit} />
        <ActionButton name="Exit" actionColor={ActionColor.CANCEL} activatedOn={() => true} clickHandler={handleExit} />
        </div>
    );
}

export default ReorderSubcategories;