import Barcode from 'react-barcode';

export type skuDisplayProps = {
    sku: string;
}

// TODO: Stylize and gather common styles into shared folders
export const SkuDisplay = (props: skuDisplayProps) => {
    const renderBarcode = () => {
        return <Barcode value={props.sku} format={'CODE128'} />;
    };

    return <div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', opacity: '0.25' }}
            onMouseOver={(e) => e.currentTarget.style.opacity = '1'}
            onMouseOut={(e) => e.currentTarget.style.opacity = '0.25'}>
            <div style={{
                marginTop: '-50px',
                fontSize: '10px',
                marginBottom: '-10px',
                zIndex: 1,
            }}>
                {props.sku}
            </div>
            <div style={{
                maxHeight: '20px',
            }}>
                {renderBarcode()}
            </div>
        </div>
    </div>
        ;
}
