import { useState } from "react";

export type column = {
    schemaName: string,
    displayName: string,
    isShowing: boolean,
    isLocale?: boolean,
    render?: (value: any, id: number) => JSX.Element,
    sortKey?: (value: any) => string | number,
    disableSorting?: boolean,
}

export enum sortMode {
    ASC = 'asc',
    DESC = 'desc',
}

export type columnHeaderProps = {
    index: number,
    column: column,
    resizeLeftHandler: (e: React.MouseEvent<HTMLDivElement>) => void, // Changed to HTMLDivElement
    resizeRightHandler: (e: React.MouseEvent<HTMLDivElement>) => void, // Changed to HTMLDivElement
    reorderLeftHandler: (e: React.MouseEvent<HTMLDivElement>) => void,
    reorderRightHandler: (e: React.MouseEvent<HTMLDivElement>) => void,
    sortHandler: (isAscending: boolean) => void,
}

// TODO: Stylize and gather common styles into shared folder
export const ColumnHeader = (props: columnHeaderProps) => {
    const [isAscending, setIsAscending] = useState(true);

    const getColumnAdjustmentButton = (
        symbol: string,
        handler: (e: React.MouseEvent<HTMLDivElement>) => void,
        stopPropagation: boolean = false,
    ) => {
        return <div
            style={{ cursor: 'col-resize', margin: 2, width: '5px', height: '100%', userSelect: 'none', opacity: '0.5' }}
            onMouseDown={(e) => {
                stopPropagation && e.stopPropagation();
                handler(e);
            }}
            onMouseEnter={(e) => {
                e.currentTarget.style.opacity = '1';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.opacity = '0.5';
            }}
        >
            {symbol}
        </div>
    }

    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'calc(100% - 10px)', // Adjusted to account for margin
    }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <div onMouseDown={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                const isLeftHalf = e.clientX - rect.left < rect.width / 2;
                if (isLeftHalf) {
                    props.resizeLeftHandler(e);
                } else {
                    props.resizeRightHandler(e);
                }
            }} style={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                backgroundColor: 'lightgray',
                border: '1px solid #bfbfbf',
                borderRadius: '5px',
                padding: '5px',
                margin: '0 5px', // Adjusted to account for flexbox alignment
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                userSelect: 'none', // Prevent text selection to not obstruct dragging resize mechanism
            }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '5px' }}>
                        <button onClick={(e) => {
                            props.sortHandler(isAscending);
                            setIsAscending(!isAscending);
                        }} style={{ cursor: 'pointer' }}>{isAscending ? '↑' : '↓'}</button>
                    </div>
                    {getColumnAdjustmentButton('<', props.reorderLeftHandler, false)}
                    <span style={{ flexGrow: 1, margin: '0 10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.column.displayName}</span>
                    {getColumnAdjustmentButton('>', props.reorderRightHandler, false)}
                </div>
            </div>
        </div>
    </div>
}
