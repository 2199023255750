import { useDispatch, useSelector } from "react-redux";
import AuthPanel from "./components/headers/AuthPanel";
import { SettingsPanel } from "./components/headers/SettingsPanel";
import { BodyContainer } from "./component-layout/BodyContainer";
import { Header } from "./component-layout/HeaderContainer";
import { ModalContainer } from "./component-layout/ModalOverlay";
import { PrimarySidebar } from "./component-layout/PrimarySidebar";
import { SecondarySidebar } from "./component-layout/SecondarySidebar";
import { useEffect } from "react";
import { getAllItemsRequestAction } from "./redux-store/reducer/allItemsSlice";
import { getAllCategoriesRequestAction } from "./redux-store/reducer/allCategoriesSlice";
import { getAllSubcategoriesRequestAction } from "./redux-store/reducer/allSubcategoriesSlice";


function App() {
  const authTokenState = useSelector((state: any) => state.authToken);

  const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllItemsRequestAction({ token: authTokenState.authToken } as any));
        dispatch(getAllCategoriesRequestAction({ token: authTokenState.authToken } as any));
        dispatch(getAllSubcategoriesRequestAction({ token: authTokenState.authToken } as any));
    }, [authTokenState]);
  return (
      <div>
          <ModalContainer />
          <Header>
              <AuthPanel />
              <SettingsPanel />
          </Header>
          {authTokenState.authToken && <>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <PrimarySidebar 
                      minExpandedWidth={200}
                      initExpandedWidth={350}
                      collapsedWidth={50}
                  />
                  <BodyContainer />
                  <SecondarySidebar
                      minExpandedWidth={200}
                      initExpandedWidth={350}
                      collapsedWidth={50}
                  />
              </div>
          </>}
      </div>
  )
}

export default App;
